<template>
  <div login-required-modal :class="theme">
    <div class="dim" @click="close"></div>
    <div class="panel">
      <div class="header">
        <a class="close" @click="close">
          <svg-x />
        </a>
        <p v-html="$t(`_.${contents}`)"></p>
        <div class="btn-wrapper">
          <ColorButton class="btn-confirm" @click="resolve" :type="theme === 'dark' ? 'red' : 'dark'" color-only>{{ $t('_.membershipSite') }}</ColorButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgX from '@shared/graphics/svg-x.vue';

export default {
  name: 'LoginRequiredModal',
  lexicon: 'shared.member.login',
  components: {
    SvgX,
    ColorButton,
  },
  props: {
    options: {
      type: Object,
      default: () => ({ theme: 'light' }),
    },
  },
  computed: {
    theme() {
      return this.options.theme;
    },
    contents() {
      return this.options.contents;
    },
  },
  methods: {
    close() {
      this.resolve(false);
    },
    resolve(flag = true) {
      this.$emit('resolve', flag);
    },
  },
};
</script>
<style lang="less">
@import '~@shared/less/proj.less';

[login-required-modal] { .abs; .f; .flex-center; .z(1000); .lt;
  > .dim { .abs; .f; .bgc(@c-b07); .lt; }
  > .panel { .p(16); .w(335); .block; .rel; .br(20); .bgc(#fff); .tc;
    > .header { .p(24, 0, 0); .rel;
      .close { .abs; .rt(0, 0); .z(100);
        svg { .block; }
      }
      [color-button] { .h(48); .br(12); .fs(16); }
    }
    p { .mt(20); .mb(40); .fs(16, 26); }
    .btn-wrapper { .flex-list; }
  }
  &.light {
    > .panel { .bgc(#fff);
      > .header {
        .close {
          svg {
            .stroke-target { stroke: #191919; }
          }
        }
      }
    }
  }
  &.dark {
    > .panel { .bgc(#161a1d);
      > .header { .c(#fff);
        .close {
          svg {
            .stroke-target { stroke: #fff; }
          }
        }
      }
    }
  }
}
</style>
