import pls_2022_3 from '@/data/meta/pls_2022_3';
import pls_2023_1 from '@/data/meta/pls_2023_1';
import pls_2023_2 from '@/data/meta/pls_2023_2';
import pls_2023_3 from '@/data/meta/pls_2023_3';

export default {
  pls_2022_3,
  pls_2023_1,
  pls_2023_2,
  pls_2023_3,
};
