export default class ExecModal {
  #app;
  #modalDefineObj = {
    // WariWariResultModal: () => import('@/views/components/gameHome/wr/gameInfo/WariWariResultModal')
  };

  constructor(app) {
    this.#app = app;

    if (typeof window === 'undefined') return;

    if (this.#app.$route.query.modal) {
      this.modalNScrollHandler();
    }
  }

  modalNScrollHandler() {
    const modalName = this.#app.$route.query.modal;

    setTimeout(() => {
      const target = document.body.querySelector(`[data-exec-modal=${modalName}]`);
      if(!target) return;
      const headerHeight = document.body.querySelector('header').clientHeight + 100;
      const toOffset = target.getBoundingClientRect().top;
      window.scroll(0, toOffset - headerHeight);
      setTimeout(() => this.#app.$modal(this.#modalDefineObj[modalName]), 500);
    }, 300);
  }
}
