import { hashNumber } from '@shared/utils/hashUtils';

const colors = [
  '#8B0E0F',
  '#CC6600',
  '#C48516',
  '#2E9974',
  '#21726C',
  '#1B5496',
  '#2E318E',
  '#AE0C68',
  '#48494D',
  '#000000',
  '#69110c',
  '#aa3e02',
  '#964f02',
  '#774f2b',
  '#87623e',
  '#906511',
  '#bc9f0f',
  '#8b770c',
  '#86930c',
  '#656e0a',
  '#18574d',
  '#074249',
  '#257353',
  '#005366',
  '#18607e',
  '#187eb2',
  '#10426b',
  '#282a66',
  '#4a31a8',
  '#44168e',
  '#732999',
  '#bc5091',
  '#811049',
  '#393b37',
];

/**
 * @description
 * 미니 로거 인스턴스 생성기 & console.log wrapper
 * @example
 * const logger = Logger('my-module');
 * logger('printing a console!'); // 콘솔 메시지에 자동으로 my-module 과 함께 랜덤한 색상이 부여된다.
 */
const Logger = moduleName => {
  const color = colors[hashNumber(moduleName, 0, colors.length - 1)];
  return (...args) => console.log(`%c[${moduleName}]`, `color:${color}`, ...args);
};

export default Logger;
