<template>
  <div id="app" class="b-lvup" :class="[touch, loading, { window: isWindow }]">
    <div>
      <PubgHeaderNav :game-id="gameId" />
      <scroll-holder id="container" container-class="scroll-container" body-class="scroll-body" offset-target-class="app-header" :header-offset="45">
        <router-view />
        <AppFooter />
        <PubgGoTop />
      </scroll-holder>
    </div>
  </div>
</template>

<script>
import ScrollHolder from 'shared/plugins/inertiaScrollAgency/components/ScrollHolder.vue';
import ExecModal from '@/utils/execModal';
import AppFooter from '@/views/common/AppFooter.vue';
import PubgHeaderNav from '../../../shared/components/layout/PubgHeaderNav.vue';
import PubgGoTop from '@/views/components/common/button/PubgGoTop.vue';
import meta from '@/data/meta';

export default {
  name: 'App',
  components: { PubgGoTop, AppFooter, PubgHeaderNav, ScrollHolder },
  data() {
    return {
      isWindow: null,
      isLoading: true,
    };
  },
  metaInfo() {
    return meta[this.$route.meta.metaKey]?.[this.$route.meta.page];
  },
  computed: {
    touch() {
      return this.$store.state.browser.touch ? 'touch' : 'no-touch';
    },
    loading() {
      return this.$store.state.loading && 'loading';
    },
    gameId() {
      return this.$route.params.id || '';
    },
  },
  methods: {
    getOs() {
      this.isWindow = navigator.userAgent.toLowerCase().indexOf('win') > -1 ? 'window' : '';
    },
  },
  mounted() {
    this.getOs();
    // eslint-disable-next-line no-new
    new ExecModal(this);
  },
};
</script>

<style lang="less">
@import '~@/less/common.less';
@import '~@/less/proj.less';

#app {
  [pubg-header] {
    &::before, &::after { .bgc(transparent); }
    .contents-holder { .max-w(@screen-ds-min); }
  }
}
</style>
