import { getByteLength, getCharLength } from '@shared/utils/commonUtils';

const required = {
  validate: (value, option) => {
    const { allowFalse } = option || { allowFalse: true };
    const result = { valid: false, required: true };
    if (value === null || value === undefined) return result;
    if (Array.isArray(value) && value.length === 0) return result;
    if (value === false && !allowFalse) return result;
    result.valid = !!String(value).trim().length;
    return result;
  },
  computesRequired: true,
};

export const email = value => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);

export default {
  required,
  requiredSelect: required,
  email,
  number: value => /^[0-9]+$/.test(value),
  includeAlphabet: value => /[a-zA-Z]/.test(value),
  includeNumber: value => /[0-9]/.test(value),
  includeSpecial: value => /[`~!@#$%^&*()\-_=+|\\<>.,/?:;'"[\]{}]/.test(value),
  alphaDash: value => /^[a-zA-Z0-9_-]+$/.test(value),
  onlyEnglish: value => /^[a-zA-Z]*$/.test(value),
  password: value => /^[a-zA-Z0-9`~!@#$%^&*()\-_=+|\\<>.,/?:;'"[\]{}]*$/.test(value),
  url: value => /^(((http(s?)):\/\/)?)([0-9a-zA-Z-]+\.)+[a-zA-Z]{2,6}(:[0-9]+)?(\/\S*)?/.test(value),
  // url: value => /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/.test(value),
  youtubeUrl: value => /^https?:\/\/www.youtube.com|youtu.be/i.test(value),
  phoneNumber: value => /^0[1-9][0-9]{1,2}-[0-9]{3,4}-[0-9]{4}$/.test(value),
  notSpecial: value => /^[A-Za-z가-힣]+$/.test(value),
  onlyOneSpace: value => /^((?! {2,}).)*$/.test(value),
  noSpacePad: value => !/(^\s|\s$)/.test(value),
  byteLength: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => getByteLength(value) >= min && getByteLength(value) <= max,
  },
  charLength: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => getCharLength(value) >= min && getCharLength(value) <= max,
  },
  charLengthMin: {
    params: ['min'],
    validate: (value, { min }) => getCharLength(value) >= min,
  },
  charLengthMax: {
    params: ['max'],
    validate: (value, { max }) => getCharLength(value) <= max,
  },
  correctLength: {
    params: ['length'],
    validate: (value, { length }) => value.length === Number(length),
  },
  length: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => value.length >= min && value.length <= max,
  },
  lengthMin: {
    params: ['min'],
    validate: (value, { min }) => value.length >= min,
  },
  lengthMax: {
    params: ['max'],
    validate: (value, { max }) => value.length <= max,
  },
  minMax: {
    params: ['min', 'max'],
    validate: (value, { min, max }) => parseInt(value, 10) >= parseInt(min, 10) && parseInt(value, 10) <= parseInt(max, 10),
  },
  notEqual: {
    params: ['other'],
    validate: (value, { other }) => value !== other,
  },
  notInclude: {
    params: ['list'],
    validate: (value, { list }) => !list.includes(value),
  },
};
