export const lvupMsgTypes = [
  'NOTICE', 'TEAM_JOIN_REQUEST', 'TEAM_JOIN_CONFIRM', 'TEAM_JOIN_FAIL', 'TEAM_CAPTAIN_HANDOVER', 'TEAM_CAPTAIN_HANDOVER_CONFIRM', 'TEAM_CAPTAIN_HANDOVER_REQUEST',
  'CASH_CHARGE', 'MISSION_ACHIEVE', 'SHOP_ITEM_BUY', 'SHOP_LOTTERY_ITEM_BUY', 'LOTTERY_WIN',
];

export default {
  MATCH_START: () => import('./MatchStartItem.vue'),
  MATCH_END: () => import('./MatchEndItem.vue'),
  TOURNAMENT_CHECKIN_WILL_START: () => import('./TournamentCheckinWillStartItem.vue'),
  TOURNAMENT_CHECKIN_START: () => import('./TournamentCheckinStartItem.vue'),
  TOURNAMENT_END: () => import('./TournamentEndItem.vue'),
  TOURNAMENT_DELETED: () => import('./TournamentDeleteItem.vue'),
  APPLICATION_EXCLUDE: () => import('./ApplicationExcludeItem.vue'),
  APPLICATION_MEMBER_EXCLUDE: () => import('./ApplicationExcludeItem.vue'),
  TOURNAMENT_CHECKIN_FAILED: () => import('./TournamentCheckinFailedItem.vue'),
  TOURNAMENT_CHECKIN_SUCCESS: () => import('./TournamentCheckinSuccessItem.vue'),
  APPLICATION_CHANGE_LEADER: () => import('./ChangeLeaderItem.vue'),
  APPLICATION_SELECTED_MEMBER: () => import('./SelectedMemberItem.vue'),
  APPLICATION_REMOVED_MEMBER: () => import('./RemovedMemberItem.vue'),

  // lvup messages
  NOTICE: () => import('./NoticeItem.vue'),

  TEAM_JOIN_REQUEST: () => import('./TeamJoinItem.vue'),
  TEAM_JOIN_CONFIRM: () => import('./TeamJoinItem.vue'),
  TEAM_JOIN_FAIL: () => import('./TeamJoinItem.vue'),

  TEAM_CAPTAIN_HANDOVER_CONFIRM: () => import('./TeamHandoverConfirmItem.vue'),
  TEAM_CAPTAIN_HANDOVER_REQUEST: () => import('./TeamHandoverRequestItem.vue'),

  MISSION_ACHIEVE: () => import('./MissionAchieveItem.vue'),
  SHOP_ITEM_BUY: () => import('./ShopItemBuyItem.vue'),
  SHOP_LOTTERY_ITEM_BUY: () => import('./ShopLotteryItemBuyItem.vue'),
  LOTTERY_WIN: () => import('./LotteryWinItem.vue'),

  GF_POINT_SUPPLY: () => import('./WcgPointSupplyItem.vue'),
  LAND_TICKET_SUPPLY: () => import('./WcgTicketSupplyItem.vue'),

  CR_MATCH_START: () => import('./CrMatchStartItem.vue'),
  CR_MATCH_REGISTERED: () => import('./CrMatchRegisteredItem.vue'),
  CR_MATCH_REGISTER_FAILED: () => import('./CrMatchRegisterFailItem.vue'),
  CR_MATCH_STATE_FAILED: () => import('./CrMatchStateFailedItem.vue'),
  CR_MATCH_STATE_CANCELED: () => import('./CrMatchStateCanceledItem.vue'),

  GACHA_WIN: () => import('./GachaWinItem.vue'),
};
