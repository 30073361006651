<template>
  <div modal-container :data-mode="type" v-show="modals.length || blocked" :class="{global, clear}">
    <component v-for="modal in modals" :is="modal.component" :options="modal.options" :key="modal.id"
      @close="close(modal.id)"
      @resolve="(result) => resolve(modal.id, result)"
      @reject="(result) => reject(modal.id, result)" />
    <transition name="fade">
      <div v-if="blocked" class="blocker">
        <div class="dim" />
        <Lottie class="lottie-coaching" src="/lottie/loading-gca-symbol.json" autoplay loop v-if="type === 'coaching'" />
        <component v-else-if="blocker" :is="blocker" />
      </div>
    </transition>
  </div>
</template>

<script>
import Lottie from '@shared/components/general/Lottie.vue';

export default {
  name: 'ModalContainer',
  components: { Lottie },
  props: {
    global: { type: Boolean },
    type: String,
    blocker: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      seq: 0,
      modals: [],
      blocked: false,
      blockTimeout: -1,
    };
  },
  computed: {
    clear() {
      return !this.blocked && this.modals.every(m => m.clear);
    },
  },
  methods: {
    checkFreeze() {
      if (!this.global) return;
      if (!this.clear) this.$scroll.freeze();
    },
    checkRelease() {
      if (!this.global) return;
      if (this.blocked) return;
      if (this.modals.filter(m => !m.clear).length === 0) this.$scroll.release();
    },
    checkComponentFlag(component) {
      if (component.singleModal) {
        this.modals = this.modals.filter(m => m.component.name !== component.name);
      }
    },
    add(component, options, clear) {
      this.checkComponentFlag(component);
      return new Promise((resolve, reject) => {
        this.modals.push({ id: this.seq++, component, resolve, reject, options, clear });
        // window.history.pushState(null, '', location.href);
        this.checkFreeze();
      });
    },
    closeModal(component) {
      this.modals = this.modals.filter(m => m.component !== component);
      this.checkRelease();
    },
    getModal(id) {
      return this.modals.find(m => m.id === id);
    },
    block() {
      clearTimeout(this.blockTimeout);
      this.blocked = true;
      this.checkFreeze();
    },
    unblock() {
      this.blockTimeout = setTimeout(() => {
        this.blocked = false;
        this.checkRelease();
      }, 300);
    },
    resolve(id, result) {
      this.getModal(id).resolve(result);
      this.close(id);
    },
    reject(id, result) {
      this.getModal(id).reject(result);
      this.close(id);
    },
    close(id) {
      this.modals = this.modals.filter(m => m.id !== id);
      // if (window) window.history.back();
      this.checkRelease();
    },
    checkModal(component) {
      return this.modals.findIndex(m => (m.component.name === component.name)) !== -1;
    },
    backEventCatch() {
      if (window && window.history) {
        // eslint-disable-next-line no-multi-assign
        window.onpopstate = window.history.onpushstate = () => {
          const modalCnt = this.modals.length;
          if (modalCnt) {
            this.modals = this.modals.filter(m => m.id !== this.modals[modalCnt - 1].id);
            this.checkRelease();
          }
        };
      }
    },
  },
  mounted() {
    // this.backEventCatch();
  },
};
</script>

<style lang="less">
@import '~@shared/less/asset.less';

[modal-container] { .fix; .wh(100vw, var(--innerHeight)); .lt; .z(5000);
  @keyframes c1 {
    0% {.o(1); .t-s(1);}
    50% {.o(0); .t-s(.5);}
    100% {.o(1); .t-s(1);}
  }
  > div { .z(1); }
  .blocker { .abs; .f; .flex-center; .z(1000);
    > .dim { .abs; .f; .bgc(rgba(0, 0, 0, 0.7)); .lt; }
    .lottie-coaching {.wh(200)}
  }
  .fade-enter-active { .tr-o(0.5s, 0.3s); }
  .fade-leave-active { .tr-o(0.5s, 0.3s); }
  .fade-enter, .fade-leave-to { .o(0) }
  &.clear { .wh(0, 0); }
}
</style>
