<template>
  <button color-button :class="[type,{'color-only':colorOnly}]" @click="$emit('click',$event)">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'ColorButton',
  props: {
    type: { type: String, default: 'gray' },
    colorOnly: Boolean,
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[color-button] { .br(6); .h(40); .lh(38); .-box; transition: background-color .3s, border-color .3s; .p(0, 10);
  .txt-c(@c) { .c(@c);
    .stroke-target { stroke: @c; }
    .fill-target { fill: @c; }
  }

  &.light-gray { .txt-c(@c-title-black); .bgc(@c-base-gray);
    @{hover-press} {.bgc(@c-placeholder); .c(@c-base-gray);}
  }
  &.dark-gray { .txt-c(#979797); .bgc(rgba(59, 62, 67, 0.55)); .-a(transparent);
    @{hover-press} { .bgc(@c-placeholder); .c(@c-base-gray); }
  }
  &.gray { .txt-c(#fff); .bgc(#3b3e43); .-a(transparent);
    @{hover-press} { .bgc(@c-title-black); }
  }
  &.light-gray-line { .txt-c(@c-base-gray); .bgc(transparent); .-a(@c-base-gray);
    @{hover-press} { .txt-c(white); .bgc(@c-base-gray); }
  }
  &.dark-line { .txt-c(@c-text-dark); .bgc(transparent); .-a(@c-text-dark);
    @{hover-press} { .txt-c(@c-base-gray); .-a(@c-base-gray); }
  }
  &.red { .txt-c(#fff); .bgc(#c71f1f); .-a(transparent);
    @{hover-press} { .bgc(#e03939); }
  }
  &.blue { .txt-c(#fff); .bgc(#5975ff); .-a(transparent);
    @{hover-press} { .bgc(#4054B6); }
  }
  &.dark { .txt-c(#fff); .bgc(@c-title-black);
    @{hover-press} { .bgc(#3b3e43); }
  }
  &.white { .txt-c(@c-title-black); .bgc(white);
    @{hover-press} { .bgc(@c-base-gray); }
  }
  &.purple { .txt-c(white); .bgc(#7815f5);
    @{hover-press} { .bgc(#785AF5); }
  }
  &.orange {.txt-c(white);.bgc(#f19638)}
  &.deep-blue { .txt-c(#2ae2b3); .bgc(#474d67); }
  &.sky-blue { .txt-c(#fff); .bgc(#22b4e6); .-a(transparent);
    @{hover-press}, &:hover { .bgc(#1B90B8); }
    &:disabled { .bgc(#bce8f7); .c(rgba(255, 255, 255, 0.6)); .o(1);}
  }

  &.color-only { .-a !important; }

  .dark &.gray, .dark &.dark-gray {
    @{hover-press} { .bgc(#53575e); }
  }

  &:disabled { .bgc(#545454); .not-allowed; .o(0.5);
    @{hover-press} { .bgc(#545454); .-a(#545454); }
  }

  .dark &:disabled { .bgc(transparent); .-a(#666); .o(1); .c(#545454);
    @{hover-press} { .bgc(transparent); .-a(#666); }
  }

}

</style>
