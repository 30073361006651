export default class ChannelService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * 채널피드 메타데이터 조회
   * @param {string} leagueCode
   * @returns {Promise<FeedMeta>}
   */
  getFeedMeta(leagueCode) {
    return this.#api.get(`/leagues/${leagueCode}/feeds/metadata`);
  }

  /**
   * 채널 피드 조회
   * @param {string} category
   * @param {string} leagueCode
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<ChannelFeedList>}
   */
  getChannelFeed({ category, leagueCode, cursor = 0, size = 1 }) {
    return this.#api.get(`/leagues/${leagueCode}/feeds?category=${category}`, { cursor, size });
  }

  /**
   * 개인 채널 피드 작성
   * @param {string} leagueCode
   * @param {RequestFeedInfo} body
   * @returns {Promise<*>}
   */
  async makeFeed({ leagueCode, body }) {
    const url = `/leagues/${leagueCode}/feeds`;
    return this.#api.post(url, body);
  }

  /**
   * 개인 채널 피드 수정
   * @param {string} leagueCode
   * @param {string} feedId
   * @param {any} body
   * @returns {Promise<*>}
   */
  async editFeed({ leagueCode, feedId, body }) {
    const url = `/leagues/${leagueCode}/feeds/${feedId}`;
    return this.#api.put(url, body);
  }

  /**
   * 개인 채널 피드 삭제
   * @param {string} leagueCode
   * @param {string} feedId
   * @returns {Promise<*>}
   */
  async deleteFeed({ leagueCode, feedId }) {
    await this.#api.delete(`/leagues/${leagueCode}/feeds/${feedId}`);
  }

  /**
   * 개인 채널 피드 댓글 목록
   * @param {string} leagueCode
   * @param {string} feedId
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<*>}
   */
  async getFeedThreads({ leagueCode, feedId, cursor = 0, size = 10 }) {
    return this.#api.get(`/leagues/${leagueCode}/feeds/${feedId}/threads`, { cursor, size });
  }

  /**
   * 개인 채널 피드 댓글 작성
   * @param {string} leagueCode
   * @param {string} feedId
   * @param {string} text
   * @returns {Promise<*>}
   */
  addFeedThread({ leagueCode, feedId, text }) {
    return this.#api.post('/users/me/lvupSpecialFeedThreads', { feedId, text, leagueCode });
  }

  /**
   * 개인 채널 피드 댓글 수정
   * @param {string} gameId
   * @param {string} threadId
   * @param {string} text
   * @returns {Promise<{}>}
   */
  editFeedThread({ leagueCode, threadId, text }) {
    return this.#api.put(`/users/me/riotFeedThreads/${threadId}`, { text, leagueCode });
  }

  /**
   * 개인 채널 피드 댓글 삭제
   * @param {string} threadId
   * @returns {Promise<{}>}
   */
  deleteFeedThread(threadId) {
    return this.#api.delete(`/users/me/riotFeedThreads/${threadId}`);
  }

  getCommercialList(leagueCode) {
    return this.#api.get(`/riot/PUBG/banners/sponsor`, { leagueCode });
  }
}
