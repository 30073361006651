// noinspection JSUnresolvedVariable

import axios from 'axios';
import Pusher from 'pusher-js';
import { appendQuery } from '@shared/utils/urlUtils';
import Logger from '@shared/modules/Logger';

class ChannelDelegate {
  static #commonPusher;
  static #matchPusher;
  static #logger;

  static get log() {
    if (!ChannelDelegate.#logger) ChannelDelegate.#logger = Logger('PUSHER');
    return ChannelDelegate.#logger;
  }

  static #getPusher = (isMatch, store) => {
    let authUrl = `${process.env.VUE_APP_API_URL}/pusher/auth`;
    if (isMatch) authUrl = appendQuery(authUrl, { channelType: 'MATCH' });
    let option = { cluster: 'ap3' };
    if (store.state.auth.accessToken) {
      const headers = { Authorization: `Bearer ${store.state.auth.accessToken}` };
      option = {
        ...option,
        auth: { headers },
        authEndpoint: authUrl,
        authorizer: channel => ({
          authorize: async (socketId, callback) => {
            try {
              const { data } = await axios.post(authUrl, { socket_id: socketId, channel_name: channel.name }, { headers });
              callback(false, data.body);
            } catch (e) {
              ChannelDelegate.log('auth fail');
              callback(true);
            }
          },
        }),
      };
    }
    return new Pusher(process.env.VUE_APP_PUSHER_KEY, option);
  };

  static #getCommonPusher = store => {
    if (!ChannelDelegate.#commonPusher) {
      ChannelDelegate.#commonPusher = ChannelDelegate.#getPusher(false, store);
      window.addEventListener('unload', () => ChannelDelegate.#commonPusher.disconnect());
    }
    return ChannelDelegate.#commonPusher;
  };

  static #getMatchPusher = store => {
    if (!ChannelDelegate.#matchPusher) {
      ChannelDelegate.#matchPusher = ChannelDelegate.#getPusher(true, store);
      window.addEventListener('unload', () => ChannelDelegate.#matchPusher.disconnect());
    }
    return ChannelDelegate.#matchPusher;
  };

  /**
   * @param vnode
   * @param {string} type
   * @param {string} channelName
   * @returns {ChannelDelegate}
   */
  static getFor = (vnode, type, channelName) => {
    if (!channelName) throw new Error('channel name is required');
    if (!vnode[`__${type}_${channelName}__`]) vnode[`__${type}_${channelName}__`] = new ChannelDelegate(vnode, type, channelName);
    return vnode[`__${type}_${channelName}__`];
  };

  #vnode;
  #type;
  #pusher;
  #channelName;
  #channel;
  #callbacks;
  #expired = false;

  constructor(vnode, type, channelName) {
    this.#vnode = vnode;
    this.#type = type;
    this.#channelName = channelName;

    this.#pusher = type === 'match' ? ChannelDelegate.#getMatchPusher(vnode.$store) : ChannelDelegate.#getCommonPusher(vnode.$store);
    this.#channel = this.#pusher.subscribe(channelName);
    this.#callbacks = [];
    vnode.$once('hook:beforeDestroy', this.unsubscribe.bind(this));
    ChannelDelegate.log(`ChannelDelegate initialized (${this.#type}:${this.#channelName})`);
  }

  unsubscribe() {
    if (this.#expired) return;
    if (!Object.keys(this.#channel?.callbacks?._callbacks)?.length) {
      this.#pusher.unsubscribe(this.#channelName);
    }
    this.#channel = null;
    this.#vnode[`__${this.#type}_${this.#channelName}__`] = null;
    this.#expired = true;
    ChannelDelegate.log(`ChannelDelegate expired (${this.#type}:${this.#channelName})`);
  }

  bind(eventName, callback, context) {
    if (!eventName) throw new Error('event name is required');
    if (!callback) throw new Error('callback is required');
    if (this.#callbacks.some(c => c.eventName === eventName && c.callback === callback)) return;
    const boundCallback = callback.bind(context);
    this.#callbacks.push({ eventName, callback, boundCallback });
    this.#channel.bind(eventName, boundCallback);
  }

  unbind(eventName, callback) {
    if (!eventName) throw new Error('event name is required');
    if (!callback) throw new Error('callback is required');
    const entry = this.#callbacks.find(c => c.eventName === eventName && c.callback === callback);
    if (entry) this.#channel.unbind(eventName, entry.boundCallback);
    this.#callbacks = this.#callbacks.filter(c => c.eventName !== eventName && c.callback !== callback);
    if (this.#callbacks.length === 0) this.unsubscribe();
  }
}

/**
 * client side 에서만 동작하여야 하고 아마도 component의 mounted hook 에서 사용하겠지만 혹시 모를 상황을 고려하여 server side 에서 호출된 경우 무시하도록 함
 */
export default {
  install(Vue) {
    if (!TARGET_NODE && process.env.NODE_ENV !== 'production') {
      ChannelDelegate.log('console has been enabled');
      // Pusher.logToConsole = true;
      // Pusher.log = ChannelDelegate.log;
    }

    Vue.prototype.$bindPushEvent = function (channelName, eventName, callback) {
      if (TARGET_NODE) return;
      ChannelDelegate.getFor(this, 'common', channelName).bind(eventName, callback, this);
    };

    Vue.prototype.$bindMatchEvent = function (channelName, eventName, callback) {
      if (TARGET_NODE) return;
      ChannelDelegate.getFor(this, 'match', channelName).bind(eventName, callback, this);
    };

    Vue.prototype.$unbindPushEvent = function (channelName, eventName, callback) {
      if (TARGET_NODE) return;
      ChannelDelegate.getFor(this, 'common', channelName).unbind(eventName, callback);
    };

    Vue.prototype.$unbindMatchEvent = function (channelName, eventName, callback) {
      if (TARGET_NODE) return;
      ChannelDelegate.getFor(this, 'match', channelName).unbind(eventName, callback);
    };
  },
};
