<script>
export default {
  name: 'ClientOnly',
  functional: true,
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
      /**
       * 추후에 필요하다면 태그를 추가해 주세요.
       * 혹 validator 가 SSR 의 가드의 의미로 작성이 되어 있기 때문에
       * 불필요하다고 느끼 신다면 validator 를 지워주셔도 무방합니다.
       * by Eddie
       */
      validator(value) {
        const INVALID_PROP_TYPE_INDEX = -1;
        return ['div', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'figure'].indexOf(value) !== INVALID_PROP_TYPE_INDEX;
      },
    },
  },
  render(h, { parent, slots, props }) {
    const { default: defaultSlots = [], placeholder: placeholderSlot } = slots();
    const { placeholder, tag } = props;
    if (parent._isMounted) return defaultSlots;
    parent.$once('hook:mounted', () => {
      parent.$forceUpdate();
    });
    if (placeholder || placeholderSlot) return h(tag, {}, placeholder || placeholderSlot);
    return defaultSlots.length > 0 ? defaultSlots.map(() => h(false)) : h(false);
  },
};
</script>
