import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';

export default () => {
  ValidationProvider.mixin({ props: { tag: { default: 'div' }, slim: { default: true } } });
  ValidationObserver.mixin({ props: { tag: { default: 'fieldset' } } });
  const extendRules = rules => {
    Object.keys(rules).forEach(type => {
      let rule;
      switch (typeof rules[type]) {
        case 'object':
          rule = rules[type];
          break;
        case 'function':
          rule = { validate: rules[type] };
          break;
        default:
          throw new Error(`[validate] invalid rule '${type}'`);
      }
      rule.message = (_, params) => params;
      extend(type, rule);
    });
  };

  const validate = async function(validator, errorCallback) {
    if (Array.isArray(validator)) {
      for (const v of validator) await validate(v);
      return;
    }
    const result = await validator.validate();
    if (!result) {
      if (errorCallback) errorCallback(validator);
      throw Object.values(validator.errors).filter(a => a.length)[0][0];
    }
    if (typeof result === 'object') {
      if (!result.valid) {
        if (errorCallback) errorCallback(validator);
        throw result.errors[0];
      }
    }
  };
  return { extend: extendRules, validate };
};
