export default class SpecialService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * @returns {Promise<ScrimInfo[]>}
   */
  getPubgScrims() {
    return this.#api.get('/lvup-specials/scrims/pubg-steam');
  }
}
