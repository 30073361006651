import Logger from '@shared/modules/Logger';

import axios from 'axios';

const log = Logger('time.store');

export default () => ({
  state: {
    diff: 0,
  },
  getters: {
    timeDiff(state) { return state.diff; },
  },
  mutations: {
    timeSync(state, serverTime) {
      state.diff = serverTime - +new Date();
      log(`time has been initialized server:${serverTime} - diff:${state.diff}`);
    },
  },
  actions: {
    async timeInit({ commit }) {
      try {
        const { data } = await axios.get(process.env.VUE_APP_TIME_URL);
        commit('timeSync', data.body.timestamp);
      } catch (e) {
        log('error while setting time diff', e);
      }
    },
  },
});
