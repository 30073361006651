export default () => ({
  namespaced: true,
  state: {
    /** @type {GameInfo[]} */
    games: null,
    /** @type {Object.<string, GameInfo>} */
    gamesByKey: null,
    countries: null,
    /** @type{?string} */
    referer: '',
  },
  getters: {
    showPubgGnb: (state, getters, rootState) => rootState.route?.path.includes('/pubg/'),
  },
  mutations: {
    setGames(state, games) {
      state.games = games;
      state.gamesByKey = games.reduce((acc, cu) => {
        acc[cu.id] = cu;
        return acc;
      }, {});
    },
    setCountries(state, countries) {
      state.countries = countries;
    },
    setReferer(state, newReferer) {
      state.referer = newReferer;
    },
  },
});
