import { createMeta } from '@/data/meta/utils';

const image = '/img/pages/pls202302/meta.jpg';
const keywords = '펍지 아마추어 리그, 펍지 아마추어 리그, 레벨업 펍지, 레벨업 , 레벨업 배그, 레벨업 펍지게임즈, 배그 아마추어리그, 배틀그라운드 아마추어 리그, 배그 아마추어, e스포츠 아마추어 대회, 이스포츠 아마추어 대회, 아마추어 대회, 레벨업 대회, BATTLEGROUND, 2022 배그 도네이션 컵 , 배틀그라운드 아마추어 리그, 배틀그라운드 아마추어 대회, 배틀그라운드 대회, 배틀그라운드 리그, 배틀그라운드 공식 대회, 배틀그라운드 아마추어 대회, 배틀그라운드 레벨업, 펍지 배틀그라운드, 펍지 배틀그라운드 대회, 배틀그라운드 배그 도네이션 컵, 배틀그라운드 대회, 배틀그라운드, BATTLEGROUND 대회, 배그, 배그 대회, BATTLEGROUND 아마추어 대회, 배그 아마추어 대회, BATTLEGROUND 아마추어 리그, 배그 아마추어 리그, 펍지 BATTLEGROUND, 펍지 공식 대회, 펍지 공식 아마추어 대회, 라이어 공식 아마추어 리그, 공식 배그 대회, 공식 배그 리그';
export default {
  home: {
    title: '[2023 PUBG PLS] 대회 신청',
    ...createMeta({
      title: '[2023 PUBG PLS] 대회 신청',
      description:
        '총 상금 300만원과 PGS 진출 시드권을 통해 프로게이머를 향한 꿈을 이룰 수 있는 기회! 2023년 PUBG 공식 아마추어 리그에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  guide: {
    title: '[2023 PUBG PLS] 대회 안내',
    ...createMeta({
      title: '[2023 PUBG PLS] 대회 안내',
      description: '총 상금 300만원과 PGS 진출 시드권을 통해 프로게이머를 향한 꿈을 이룰 수 있는 기회! 2023년 PUBG 공식 아마추어 리그에 참여해 보세요.',
      keywords,
      image,
    }),
  },
  result: {
    title: '[2023 PUBG PLS] 대회 결과',
    ...createMeta({
      title: '[2022 PUBG PLS] 대회 결과',
      description: '대회 결과 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
  feed: {
    title: '[2023 PUBG PLS] 공지/이벤트',
    ...createMeta({
      title: '[2022 PUBG PLS] 공지/이벤트',
      description: '대회 관련 공지 및 이벤트 내용을 확인해 보세요.',
      keywords,
      image,
    }),
  },
};
