export default class RouterProxy {
  /** @type {VueRouter} */
  #router;
  #nextRoute;

  constructor(router) {
    this.#router = router;
  }

  async push(...arg) {
    try {
      return await this.#router?.push(...arg);
    } catch (e) { /* empty */ }
  }

  async replace(...arg) {
    try {
      return await this.#router?.replace(...arg);
    } catch (e) { /* empty */ }
  }

  get currentRoute() {
    return this.#router?.currentRoute;
  }

  setRouter(router) {
    this.#router = router;
  }

  get nextRoute() {
    return this.#nextRoute || this.currentRoute;
  }

  set nextRoute(v) {
    this.#nextRoute = v;
  }

  async onReady() {
    return new Promise(resolve => this.#router.onReady(resolve));
  }
}
