import axios from 'axios';

export default class LeaderBoardService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  getTopRanks({ leagueId, rankId, params }) {
    return this.#api.get(`/leagues/${leagueId}/university-rankings/${rankId}/top-teams`, params);
  }

  getUserRanks({ isMy, params }) {
    let url = '/lvup-rankings/user-rankings';
    url += isMy ? '/users/me' : '';
    return this.#api.get(url, params);
  }

  getMatchSummary(leagueCode) {
    return this.#api.get(`/leagues/${leagueCode}/summary`);
  }

  getMyLeague(leagueCode) {
    return this.#api.get(`/leagues/${leagueCode}/users/me`);
  }

  /**
   * @param {string} arenaId
   */
  async getFinalLeaderBoardList(arenaId) {
    let result;
    try {
      const { data } = await axios.get(`/prod-api/arenas/${arenaId}/grouped-round/leaderboard`);
      result = data.body;
    } catch (e) {
      return;
    }
    return result;
  }

  async getFinalApplication(arenaId) {
    let result;
    try {
      const { data } = await axios.get(`/prod-api/arenas/${arenaId}/applications`);
      result = data.body;
    } catch (e) {
      return;
    }
    return result;
  }
}
