<template>
  <img retry-img v-if="success" :src="computedSrc" @click="$emit('click')"/>
  <span retry-img v-else><slot name="skeleton" /></span>
</template>

<script>
import { appendQuery } from '@shared/utils/urlUtils';
import { sleep } from '@shared/utils/commonUtils';

export default {
  name: 'RetryImg',
  props: {
    src: { type: String, required: true },
    retryCount: { type: Number, default: 3 },
    retryInterval: { type: Number, default: 700 },
  },
  data() {
    return {
      retry: 0,
      success: false,
      checkImage: null,
    };
  },
  computed: {
    computedSrc() {
      return appendQuery(this.src, { retry: this.retry });
    },
  },
  methods: {
    onLoad() {
      this.success = true;
    },
    async onError() {
      if (this.retry >= this.retryCount) {
        this.$emit('fail');
        return;
      }
      await sleep(this.retryInterval);
      this.retry += 1;
      this.checkImage.src = this.computedSrc;
    },
  },
  mounted() {
    this.checkImage = new Image();
    this.checkImage.addEventListener('load', this.onLoad);
    this.checkImage.addEventListener('error', this.onError);
    this.checkImage.src = this.computedSrc;
  },
  beforeDestroy() {
    this.checkImage.removeEventListener('load', this.onLoad);
    this.checkImage.removeEventListener('error', this.onError);
  },
};
</script>
<style lang="less">
@import '~@shared/less/proj.less';
[retry-img] { .rel; .crop;
  & > * { .abs; .lt; .f; }
}
</style>
