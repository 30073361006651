const view = path => () => import(`../views/pages/pls_2022_3/${path}.vue`);
const page = path => () => import(`../views/pages${path}.vue`);
const meta = { leagueCode: 'PUBG_AMATEUR_PLS_3', metaKey: 'pls_2022_3' };

export default () => [
  {
    path: '/b/:id(pubg)/:league(pls_2022_3)',
    name: 'pubgHome_2022_3',
    component: view('PubgHome'),
    meta: { ...meta, page: 'home' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2022_3)/leagueGuide',
    name: 'pubgLeagueGuide_2022_3',
    component: view('PubgIntro'),
    meta: { ...meta, page: 'guide' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2022_3)/leagueResult',
    name: 'pubgLeagueResult_2022_3',
    component: view('PubgResult'),
    meta: { ...meta, page: 'result' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2022_3)/newsFeed',
    name: 'newsFeed_2022_3',
    component: page('/NewsFeed'),
    meta: { ...meta, page: 'feed' },
  },
];
