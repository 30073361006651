const {
  VUE_APP_COOKIE_DOMAIN,
  VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY,
  VUE_APP_COOKIE_USER_KEY,
  VUE_APP_COOKIE_ACCESS_TOKEN_KEY,
  VUE_APP_COOKIE_AUTO_LOGIN_KEY,
  VUE_APP_COOKIE_GNB_GAME_ID_KEY,
  VUE_APP_COOKIE_TIME_DIFF_KEY,
  VUE_APP_COOKIE_SLUG_ID,
  VUE_APP_COOKIE_VISITED_GAMES,
  VUE_APP_COOKIE_RIOT_GAME_ID,
  VUE_APP_COOKIE_PUBG_GAME_ID,
} = process.env;

const DEFAULT_EXPIRES_DAY = parseFloat(VUE_APP_COOKIE_DEFAULT_EXPIRES_DAY);
const SESSION_ONLY = 0;

export default class CookieService {
  /** @type {JsCookie} */
  #cookies;

  constructor(cookies) {
    this.#cookies = cookies;
  }

  /**
   * @description
   * 가지고 있는 모든 쿠키의 키를 반환
   * @returns {string[]}
   */
  get keys() {
    return Object.keys(this.#cookies.getJSON());
  }

  /**
   * @description
   * 특정 정규식을 만족하는 모든 key를 찾아서 반환
   * @param {RegExp} regex
   * @returns {string[]}
   */
  findKeys(regex) {
    return this.keys.filter(v => regex.test(v));
  }

  /**
   * @param {JsCookie} cookies
   */
  setCookies(cookies) {
    this.#cookies = cookies;
  }

  /**
   * @param {string} key
   * @param {any} value
   * @param {number | Date} expires
   */
  setCookie(key, value, expires = DEFAULT_EXPIRES_DAY) {
    this.#cookies.set(key, value, { domain: VUE_APP_COOKIE_DOMAIN, path: '/', expires: expires === SESSION_ONLY ? undefined : expires });
  }

  /**
   * @param {string} key
   */
  removeCookie(key) {
    this.#cookies.remove(key, { domain: VUE_APP_COOKIE_DOMAIN, path: '/' });
  }

  /** @returns {string} */
  getUser = () => this.#cookies.get(VUE_APP_COOKIE_USER_KEY);
  /** @returns {string} */
  getToken = () => this.#cookies.get(VUE_APP_COOKIE_ACCESS_TOKEN_KEY);
  /** @returns {boolean} */
  getAutoLogin = () => this.#cookies.getJSON(VUE_APP_COOKIE_AUTO_LOGIN_KEY);
  /** @returns {string} */
  getGnbGameId = () => this.#cookies.get(VUE_APP_COOKIE_GNB_GAME_ID_KEY);
  /** @returns {string} */
  getTimeDiff = () => this.#cookies.get(VUE_APP_COOKIE_TIME_DIFF_KEY);
  /** @returns {string} */
  getSlugId = () => this.#cookies.get(VUE_APP_COOKIE_SLUG_ID);
  /** @returns {array} */
  getVisitedGameId = () => {
    const v = this.#cookies.get(VUE_APP_COOKIE_VISITED_GAMES);
    return v && v.length ? v.split(',') : null;
  };
  /** @returns {string} */
  getExternalGameId = type => {
    switch (type) {
      case 'ri':
        return this.#cookies.get(VUE_APP_COOKIE_RIOT_GAME_ID);
      case 'pubg':
        return this.#cookies.get(VUE_APP_COOKIE_PUBG_GAME_ID);
      default:
        return '';
    }
  };

  /** @param {string} userId */
  setUser = userId => this.setCookie(VUE_APP_COOKIE_USER_KEY, userId, this.getAutoLogin() ? DEFAULT_EXPIRES_DAY : SESSION_ONLY);
  /** @param {string} token */
  setToken = token => this.setCookie(VUE_APP_COOKIE_ACCESS_TOKEN_KEY, token, this.getAutoLogin() ? DEFAULT_EXPIRES_DAY : SESSION_ONLY);
  /** @param {boolean} value */
  setAutoLogin = value => this.setCookie(VUE_APP_COOKIE_AUTO_LOGIN_KEY, value);
  /** @param {string} gameId */
  setGnbGameId = gameId => this.setCookie(VUE_APP_COOKIE_GNB_GAME_ID_KEY, gameId);
  /** @param {number} timeDiff */
  setTimeDiff = timeDiff => this.setCookie(VUE_APP_COOKIE_TIME_DIFF_KEY, timeDiff);
  /** @param {string} slugId */
  setSlugId = slugId => this.setCookie(VUE_APP_COOKIE_SLUG_ID, slugId);
  /** @param {string} gameId */
  setVisitedGameId = gameId => {
    const gameIds = this.getVisitedGameId() || [];
    gameIds.unshift(gameId);
    const filtered = [...new Set(gameIds)].slice(0, 6);

    this.setCookie(VUE_APP_COOKIE_VISITED_GAMES, filtered.join(','));
  };
  /**
   * @param {string} gameId
   * @param {string} type
   */
  setExternalGameId = (gameId, type) => {
    if (!gameId) return;
    switch (type) {
      case 'riot':
        this.setCookie(VUE_APP_COOKIE_RIOT_GAME_ID, gameId);
        break;
      case 'pubg':
        this.setCookie(VUE_APP_COOKIE_PUBG_GAME_ID, gameId);
        break;
      default:
        break;
    }
  };

  removeUser = () => this.removeCookie(VUE_APP_COOKIE_USER_KEY);
  removeToken = () => this.removeCookie(VUE_APP_COOKIE_ACCESS_TOKEN_KEY);
  removeAutoLogin = () => this.removeCookie(VUE_APP_COOKIE_AUTO_LOGIN_KEY);
}
