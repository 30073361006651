import VueGtag from 'vue-gtag';

export default {
  install(Vue, { router, user_id }) {
    Vue.use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GA4_KEY,
        params: { user_id },
      },
      includes: [
        { id: process.env.VUE_APP_GA3_KEY },
      ],
    }, router);
  },
};
