import { getHasAsyncDataComponentsSorted, getPathAppliedParam } from '@shared/utils/routerUtils';
import _forEach from 'lodash/forEach';
import timeConsole from '@shared/ssr/timeConsole.mjs';

export default createApp => ({ beforeCreate }) => context => new Promise((resolve, reject) => {
  (async () => {
    const { app, router, store, services } = await createApp(context.cookies, async (services, store) => {
      beforeCreate(context, store);
      await services.init(context.url, store).catch(reject);
    });

    try {
      router.onError(reject);
      await router.push(context.url);
      const matchedComponents = router.getMatchedComponents();
      if (!matchedComponents.length) return reject({ code: 404 });

      const COMPONENTS_STATE = {};
      const route = router.currentRoute;
      const sharedScope = {};
      const sortedByPriority = getHasAsyncDataComponentsSorted(matchedComponents);
      for (const components of sortedByPriority) {
        await Promise.all(components.map(component => component.asyncData({ store, route, services, sharedScope, mixinFetcher: component.mixins?.find(m => m.fetch) }).catch(e => {
          timeConsole.log(e);
        }).then(result => {
          if (!component.name) throw new Error('[asyncData] \'name\' property is required');
          if (COMPONENTS_STATE[component.name]) throw new Error(`[asyncData] duplicated name(${component.name}) property`);
          // eslint-disable-next-line no-multi-assign
          COMPONENTS_STATE[component.name] = component.__INITIAL_STATE__ = result;
        })));
      }
      for (const /** @type {{ afterAsyncData?: Function, routeMappers?: RouteMappers, name: string }} */ component of matchedComponents) {
        if (component.afterAsyncData) await component.afterAsyncData({ store, route, services, sharedScope }).catch(reject);
        if (component.routeMappers) {
          _forEach(component.routeMappers, (routeMapper, key) => {
            if (routeMapper.type !== 'param') return;
            const currentValue = route.params[key];
            if (currentValue && currentValue !== '_') return;
            let defaultValue = routeMapper.defaultParam;
            if (routeMapper.useLastValueAsDefault) {
              const savedValue = services.cookies.getCookie(`${component.name}_${key}`);
              if (savedValue) defaultValue = savedValue;
            }
            if (!defaultValue || defaultValue === '_') return;
            const matchedPath = route.matched[route.matched.length - 1].path;
            if (!matchedPath.includes(key)) return;
            reject({ code: 302, to: getPathAppliedParam(matchedPath, { ...route.params, [key]: defaultValue }) });
          });
        }
      }

      context.rendered = () => {
        if (app.$i18n) {
          context.lang = app.$i18n('lang');
          context.country = app.$i18n('country');
        }
        context.gtmId = process.env.VUE_APP_GTM_ID || '';

        context.state = store.state;
        context.state.__COMPONENTS_STATE__ = COMPONENTS_STATE;
        context.meta = app.$meta();
      };

      resolve(app);
    } catch (e) {
      if (e.code === 'NOT_FOUND') reject({ code: 404 });
      else reject({ code: 500, stack: e });
    }
  })();
});
