import axios from 'axios';
import qs from 'qs';
import _get from 'lodash/get';

/**
 * @typedef { store: StoreProxy, router: RouterProxy, modal: ModalProxy } ProxyManager
 */

export default class ApiConnection {
  /** @type{import('axios').AxiosInstance} */
  #axios;
  #getSync = {};
  /** @type {ServiceManager} */
  #services;
  #configDecorator;
  #i18n;

  /**
   * @param {string} baseURL
   * @param {ServiceManager?} services
   * @param i18n
   * @param {function(config: {}): {}} configDecorator
   */
  constructor(baseURL, services, configDecorator = v => v, i18n = null) {
    this.#services = services;
    this.#i18n = i18n;
    this.#axios = axios.create({ baseURL });
    this.#configDecorator = configDecorator;
    this.#axios.interceptors.request.use(config => {
      const accessToken = services?.store.getState('auth', 'accessToken');
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
      const lang = this.getLang();

      config.headers['X-LVUP-TIME-DIFF'] = services?.store.getState('time', 'diff') || 0;
      config.headers['Content-Type'] = 'application/json';
      config.headers['accept-language'] = lang === 'id' ? 'en' : lang;
      config.headers.Pragma = 'no-cache';
      config.headers.Expires = -1;
      config.paramsSerializer = qs.stringify;
      return config;
    });
  }

  getLang() {
    const routeLocale = this.#services?.router.nextRoute?.params?.locale;
    return routeLocale ? this.#i18n?.languageByRouteLocale(routeLocale) : 'ko';
  }

  #responseHandler = (promise, silent) => new Promise((resolve, reject) => {
    if (!silent) this.#services?.modal.block();
    promise.then(response => {
      if (!silent) this.#services?.modal.unblock();
      resolve(response.data.body || response);
    }).catch(e => {
      const code = _get(e, 'response.data.error.code') || _get(e, 'response.data.summary.code') || 'DEFAULT';
      const body = _get(e, 'response.data.body', {});
      if (!silent) this.#services?.modal.unblock();
      reject({ code, body, org: e, status: e.status });
    });
  });

  _get(path, params, config) {
    const uri = `${path}?${qs.stringify(params)}`;
    return this.#getSync[uri] || (this.#getSync[uri] = this.#axios.get(path, { ...config, params, timeout: 3000 }).then(response => {
      delete this.#getSync[uri];
      return response;
    }).catch(e => {
      delete this.#getSync[uri];
      throw e;
    }));
  }

  /**
   * @param {() => Promise<unknown>} run
   * @param {boolean} silent
   * @returns {Promise<unknown>}
   */
  async withRefresh(run, silent) {
    try {
      return await this.#responseHandler(run(), silent);
    } catch (e) {
      if (e.code === 'UNAUTHORIZED' || e.code === 'PRIVATE_EASY_BRACKET') {
        if (await this.#services.auth.refreshToken()) return this.#responseHandler(run(), silent);
      }
      throw e;
    }
  }

  /**
   * @param {string} path
   * @param {object?} params
   * @param {import('axios').AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  get(path, params, config) {
    return this.withRefresh(() => this._get(path, params, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {import('axios').AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  post(path, data, config) {
    return this.withRefresh(() => this.#axios.post(path, data, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {any?} data
   * @param {import('axios').AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  put(path, data, config) {
    return this.withRefresh(() => this.#axios.put(path, data, this.#configDecorator(config)), config?.silent);
  }

  /**
   * @param {string} path
   * @param {import('axios').AxiosRequestConfig & { silent?: boolean }?} config
   * @returns {Promise<*>}
   */
  delete(path, config) {
    return this.withRefresh(() => this.#axios.delete(path, this.#configDecorator(config)), config?.silent);
  }

  get interceptors() {
    return this.#axios.interceptors;
  }
}
