const view = path => () => import(`../views/pages/pls_2023_3/${path}.vue`);
const page = path => () => import(`../views/pages${path}.vue`);
const meta = { leagueCode: '2023_PLS_SEASON_3', metaKey: 'pls_2023_3' };

export default () => [
  {
    path: '/b/:id(pubg)',
    name: 'pubgHome_2023_03',
    component: view('PubgHome'),
    meta: { ...meta, page: 'home' },
  },
  {
    path: '/b/:id(pubg)/leagueGuide',
    name: 'pubgLeagueGuide_2023_03',
    component: view('PubgIntro'),
    meta: { ...meta, page: 'guide' },
  },
  {
    path: '/b/:id(pubg)/leagueResult',
    name: 'pubgLeagueResult_2023_03',
    component: view('PubgResult'),
    meta: { ...meta, page: 'result' },
  },
  {
    path: '/b/:id(pubg)/newsFeed',
    name: 'newsFeed_2023_03',
    component: page('/NewsFeed'),
    meta: { ...meta, page: 'feed' },
  },
];
