import Logger from '@shared/modules/Logger';
import { lvupMsgTypes } from '@shared/components/notification/itemTypes/LazyNotifyItems';
import { NotificationModal } from '@shared/components/LazyLoadings';
import NotificationItemPop from '@shared/components/notification/NotificationItemPop.vue';
import timeConsole from '@shared/ssr/timeConsole';

export default class NotificationService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ModalProxy} */
  #modal;
  /** @type {ServiceManager} */
  #services;

  #log;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#modal = services.modal;
    this.#services = services;
    this.#log = Logger('NotificationService');
  }

  /**
   * @param {'ARENA'|'LVUP'|'ALL'} type
   * @returns {Promise<number>}
   */
  async getUnreadCounts(type) {
    const { count } = await this.#api.get('/notifications/count', { isRead: false, notificationType: type });
    return count;
  }

  /**
   * @param {'ARENA'|'LVUP'} notificationType
   * @param {boolean} isRead
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<NotificationList>}
   */
  getMessages(notificationType, isRead, cursor = 0, size = 100) {
    return this.#api.get('/notifications', { notificationType, isRead, cursor, size });
  }

  /**
   *
   * @param {string} teamId
   * @returns {Promise<void>}
   */
  changeCaptainConfirm(teamId) {
    return this.#api.post(`/users/teams/${teamId}/change-captain-confirm`);
  }

  setApp(app) {
    if (this.#services.auth.isLogin) {
      const privateChannel = `private-${this.#services.store.state.auth.userId}`;
      app.$bindPushEvent(privateChannel, 'notification', this.notifyPusherEventBinder.bind(this));
    }
  }

  async notifyPusherEventBinder({ message }) {
    const type = lvupMsgTypes.some(event => event === message.event) ? 'LVUP' : 'ARENA';
    const prevUnReadCount = this.#services.store.getState('notification', 'unReadCount') ?? 0;
    this.#services.store.commit('notification/setRecentReceivedType', type);
    this.#services.store.commit('notification/setUnreadCount', prevUnReadCount + 1);
    await this.#modal.modal(NotificationItemPop, { message });
  }

  /**
   * @param {string} notificationId
   * @returns {Promise<*>}
   */
  markAsRead(notificationId) {
    return this.#api.put(`/notifications/${notificationId}/read`);
  }

  /**
   * @param {string} notificationId
   * @returns {Promise<*>}
   */
  deleteMessage(notificationId) {
    return this.#api.delete(`/notifications/${notificationId}`);
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async init() {
    try {
      if (this.#services.auth.isLogin) {
        const unReadCount = await this.getUnreadCounts('ALL');
        this.#services.store.commit('notification/setUnreadCount', unReadCount);
      }
    } catch (e) {
      timeConsole.log(e);
    }
  }
}
