<template>
  <router-link :to="localeTo" :custom="custom" :exact="exact" :exact-path="exactPath" :append="append" :replace="replace"
               :active-class="activeClass" :exact-active-class="exactActiveClass" :aria-current-value="ariaCurrentValue" @touchstart.passive="() => {}"><slot /></router-link>
</template>

<script>

export default {
  name: 'LocaleLink',
  props: {
    to: { type: [Object, String], required: true },
    custom: Boolean,
    exact: Boolean,
    exactPath: Boolean,
    append: Boolean,
    replace: Boolean,
    activeClass: { type: String, default: 'active' },
    exactActiveClass: String,
    ariaCurrentValue: String,
  },
  computed: {
    localeTo() {
      return typeof this.to === 'string' ? `${this.$i18n('path')}${this.to}` : this.to;
    },
  },
};
</script>
