<template>
  <div notification-pop :class="{ 'on-wcg': isWcg }">
    <div class="dim" @click="close"></div>
    <component :is="useNoticeFallback(message.event)" :message="message" @close="close" @read-item="readItem" />
  </div>
</template>

<script>
import LazyNotifyItems from '@shared/components/notification/itemTypes/LazyNotifyItems';
import { getter, state } from '@shared/utils/storeUtils';

export default {
  name: 'NotificationItemPop',
  components: LazyNotifyItems,
  props: {
    options: { type: Object, default: () => ({ message: null }) },
  },
  watch: {
    '$route.path': function() {
      this.close();
    },
  },
  computed: {
    userId: getter('auth', 'userId'),
    isWcg: getter('info', 'showWcgGnb'),
    unReadCount: state('notification', 'unReadCount'),
    message() {
      if (!this.options.message) return null;
      return {
        ...this.options.message,
        id: this.options.message.notificationId,
        realTime: true,
        userId: this.userId,
      };
    },
  },
  mounted() {
    if(this.message?.event === 'TOURNAMENT_CHECKIN_CHANGED') {
      this.close();
    }
  },
  methods: {
    useNoticeFallback(name) { return name in LazyNotifyItems ? name : 'NOTICE'; },
    close() {
      this.$emit('close');
    },
    async readItem({ notificationId, userNotificationId }) {
      this.close();
      notificationId = notificationId || userNotificationId;
      if(!notificationId) return;
      await this.$services.notification.markAsRead(notificationId);
      this.$store.commit('notification/setUnreadCount', this.unReadCount - 1);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';

[notification-pop] { .abs; .lt(0, 0); .z(1); .wh(100%); .p(0, 10); .flex-center;
  .dim { .abs; .lt(0, 0); .z(1); .wh(100%); .bgc(rgba(0, 0, 0, 0.4)); }
  [notification-item] { .rel; .z(2); .w(100%); .max-w(355); .mb(0); .pt(41); .pb(30); .br(20); .bgc(#FFF);
    .header { .mb(0); .pb(0); justify-content: center; .-b;
      .option { .hide; }
      .title { .block;
        .icon { .hide; }
        .lvup-icon { .block; .mh-c; .mb(14);}
        > span { .block; .fs(18, 24); .c(#000); .bold; .tc; .pre-line; }
      }
    }
    .body { .mt(20); .p(24, 16); .bgc(#F5F4F3); .br(12);
      > div { .max-h(228); .min-h(66); .auto-scroll;
        p { .fs(14, 20); .c(#191919);
          .link { .-b(#191919); }
        }
      }
      [count-down]{ .c(#191919); }
    }
    .btn-wrap { .tc;
      button { .h(48); .ml(10); .fs(16); .c(#FFF); .vam; .bgc(#DF3A3A); .br(24); background-image: none;
        &:hover { .c(#FFF) !important; }
        &.btn-close { .ib; .bgc(#191919); }
        &:first-child {
          &:nth-last-child(1) { .w(100%); .ml(0); }
          &:nth-last-child(2), &:nth-last-child(2) ~ button { .calc-w(50%, 10); .m(0, 5); }
        }
      }
    }

    .noti-test{ .hide; }
  }

  &.on-wcg [notification-item] { .bgc(#252437);
    .header {
      .title {
        .lvup-icon { .hide; }
        .wcg-icon { .block; .mh-c; .mb(20); }
        > span { .c(#fff); }
      }
    }
    .body { .bgc(rgba(0,0,0,0.15));
      > div {
        p { .c(#fff);
          .link { .-b(#fff); }
        }
      }
      [count-down]{ .c(#fff); }
    }
    .btn-wrap {
      button { background: linear-gradient(109.8deg, #5B00B7 0%, #A500F2 100%);
        &.btn-close { background: #4B476F; }
      }
    }
  }

  @media (@tl-up) {
    [notification-item] { .max-w(500);
      .body {
        > div { .max-h(438); }
      }
    }
  }
}
</style>
