<template>
  <div popover-container>
    <div class="popover-position" v-for="popover in popovers" :key="popover.id" :style="popover.position">
      <PopoverItem class="popover" :style="popover.style" :class="[popover.dirClass, popover.className, popover.tipPosition, popover.theme, {'use-component': popover.component}]" @remove="remove(popover.el)" :use-click="popover.useClick">
        <div class="component-crop" :class="{'has-close': popover.useClick && popover.useClose}">
          <component :is="popover.component" v-if="popover.component" @resolve="v => componentResolve(v, popover)"/>
          <span class="popover-text" v-html="popover.text" v-else />
        </div>
        <a @click="remove(popover.el)" v-if="popover.useClick && popover.useClose"><component :is="closeComponent" class="popover-close" /></a>
      </PopoverItem>
    </div>
  </div>
</template>

<script>
import PopoverItem from '@shared/plugins/popover/components/PopoverItem.vue';

export default {
  name: 'Popover',
  components: { PopoverItem },
  props: { closeComponent: { type: null } },
  data() {
    return {
      popovers: [],
    };
  },
  methods: {
    /**
     *
     * @param {HTMLElement&{binding: {modifiers: string[], value: * }}} el
     * @param {{$t: function(string|string[]): string}} context
     */
    add({ el, context }) {
      if (el.binding.value?.ignore) return;
      this.popovers.push(this.makePopover(el, context));
    },
    remove(el) {
      this.popovers = this.popovers.filter(p => p.el !== el);
    },
    update({ el, context }) {
      const popover = this.popovers.find(p => p.el === el);
      if (!popover) return;
      const { binding } = el;
      if (binding.value?.ignore) {
        this.remove(el);
        return;
      }

      let text;
      let translate;

      if (typeof binding.value === 'object') {
        text = binding.value.text;
        translate = binding.value.translate ?? true;
      } else {
        text = binding.value;
        translate = true;
      }
      popover.text = translate && text ? context.$t(text) : text;
    },
    makePopover(el, context) {
      const { binding } = el;
      let text;
      let maxWidth;
      let maxHeight;
      let offset;
      let translate;
      let direction;
      let align;
      let className;
      let useClose;
      let component;
      let callback;
      const theme = `theme-${binding?.value?.theme ?? 'default'}`;
      let useClick = false;

      if (typeof binding.value === 'object') {
        text = binding.value.text;
        maxWidth = binding.value.maxWidth || 300;
        maxHeight = binding.value.maxHeight || 200;
        offset = binding.value.offset || 10;
        translate = binding.value.translate ?? true;
        useClick = binding.value.useClick;
        useClose = binding.value.useClose;
        direction = binding.value.direction;
        align = binding.value.align;
        className = binding.value.class;
        component = binding.value.component;
        callback = binding.value.callback;
      } else {
        text = binding.value;
        maxWidth = 300;
        maxHeight = 200;
        offset = 10;
        translate = true;
      }

      const modifiers = Object.keys(binding.modifiers);
      direction = direction || modifiers[0] || 'top';

      const preferHorizontal = direction === 'left' || direction === 'right';
      align = align || modifiers[1] || (preferHorizontal ? 'top' : 'center');

      const bound = JSON.parse(JSON.stringify(el.getBoundingClientRect()));

      if (!preferHorizontal && bound.width < 48) {
        const diff = 48 - bound.width;
        bound.left -= diff >> 1;
        bound.right += diff >> 1;
        bound.width = 48;
      }

      if (preferHorizontal && bound.height < 30) {
        const diff = 30 - bound.height;
        bound.top -= diff >> 1;
        bound.bottom += diff >> 1;
        bound.height = 30;
      }

      const innerWidth = document.body.offsetWidth;

      const position = { width: `${maxWidth}px` };
      // 10은 anchor 크기
      const style = { 'max-width': `${maxWidth}px`, '--target-center': `${(bound.width - 10) >> 1}px`, '--target-middle': `${(bound.height - 10) >> 1}px` };
      let tipPosition;
      let dirClass;
      const rightSpace = innerWidth - bound.right;
      const bottomSpace = window.innerHeight - bound.bottom;

      if (preferHorizontal) { // 좌우 선호
        // 왼쪽을 원하고 왼쪽에 공간이 있거나, 오른쪽을 원하고 오른쪽 공간이 왼쪽 공간보다 작은게 확실하고 오른쪽에 공간이 부족하다면 왼쪽으로
        // eslint-disable-next-line no-mixed-operators
        if ((direction === 'left' && bound.left > maxWidth + offset) || (direction === 'right' && bound.left > rightSpace && rightSpace < maxWidth + offset)) {
          position.right = `${innerWidth - bound.left}px`;
          style.right = `${offset}px`;
          dirClass = 'l';
        } else { // 아니면 오른쪽으로
          position.left = `${bound.right}px`;
          style.left = `${offset}px`;
          dirClass = 'r';
        }

        if (align === 'center') {
          position.top = `${(bound.top + bound.bottom) >> 1}px`;
          style.top = 0;
          style.transform = 'translateY(-50%)';
          tipPosition = 'hc';
        } else if (align === 'bottom') {
          position.top = `${bound.bottom}px`;
          style.bottom = '0';
          tipPosition = 'hb';
        } else {
          position.top = `${bound.top}px`;
          style.top = '0';
          tipPosition = 'ht';
        }
      } else { // 좌우 선호 아니면 위아래로
        // 위쪽을 원하고 위쪽에 공간이 있거나, 아래쪽을 원하고 아랫쪽 공간이 윗쪽 공간보다 작은게 확실하고 아래쪽에 공간이 없다면 위로
        if ((direction === 'top' && bound.top > maxHeight + offset) || (direction === 'bottom' && bound.top > bottomSpace && bottomSpace < maxHeight + offset)) {
          position.top = `${bound.top}px`;
          style.bottom = `${offset}px`;
          dirClass = 't';
        } else { // 아니면 아래로
          position.top = `${bound.bottom}px`;
          style.top = `${offset}px`;
          dirClass = 'b';
        }

        if (align === 'center') { // 좌우 가운데 정렬
          position.left = `${(bound.left + bound.right) >> 1}px`;
          style.left = 0;
          style.transform = 'translateX(-50%)';
          tipPosition = 'vc';
        } else if (align === 'right') {
          position.right = `${innerWidth - bound.right}px`;
          style.right = '0';
          tipPosition = 'vr';
        } else {
          position.left = `${bound.left}px`;
          style.left = '0';
          tipPosition = 'vl';
        }
      }
      return { el, text: translate && text ? context.$t(text) : text, position, style, dirClass, tipPosition, theme, className, useClick, useClose, component, callback };
    },
    clear() {
      this.popovers = [];
    },
    componentResolve(v, popover) {
      if (popover.callback) popover.callback(v);
      this.remove(popover.el);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.clear);
    window.addEventListener('resize', this.clear);
  },
};
</script>

<style lang="less">
@import '~@shared/less/asset.less';

[popover-container] { .abs; .lt; .z(4000);
  .popover-position { .fix;
    .popover { .abs;
      .component-crop { .p(5, 10); .fs(12, 18); .c(rgba(255, 255, 255, 0.5)); .bgc(#0e1415); .-a(rgba(255, 255, 255, 0.1)); .br(4); .pre-line; .crop;
        &.has-close { .pr(30) }
      }
      &:after { .cnt; .wh(7); .bgc(#0e1415); .-t(rgba(255, 255, 255, 0.1)); .-l(rgba(255, 255, 255, 0.1)); .abs; }
      &.r:after { transform: rotate(315deg); .l(-4); }
      &.t:after { transform: rotate(225deg); .b(-4); }
      &.l:after { transform: rotate(135deg); .r(-4); }
      &.b:after { transform: rotate(45deg); .t(-4); }

      &.vl:after { .l(var(--target-center)); }
      &.vc:after { .l(calc(50% - 5px)) }
      &.vr:after { .r(var(--target-center)); }

      &.ht:after { .t(var(--target-middle)); }
      &.hc:after { .t(calc(50% - 5px)) }
      &.hb:after { .b(var(--target-middle)); }

      &.theme- {
        &coaching {.c(#fff); .lh(20);
          .popover-text { .ellipsis(4); }
        }
      }
      .popover-close { .abs; .rt(8, 7); .pointer; }

      &.use-component {
        @wcg-bg: #3A3957;
        @wcg-border: fade(#ADA8E3, 40%);
        @default-bg: #1E2629;
        @default-border: fade(#FFF, 15%);
        .component-crop { .p; .br(8); .bgc(@default-bg); .-a(@default-border); }
        &:after { .bgc(@default-bg); .-t(@default-border); .-l(@default-border); }

        &.theme-wcg {
          .component-crop { .bgc(@wcg-bg); .-a(@wcg-border); }
          &:after { .bgc(@wcg-bg); .-t(@wcg-border); .-l(@wcg-border); }
        }
      }
    }
  }
  .theme {}
}
</style>
