import _ from 'lodash';
import qs from 'qs';

/**
 * @description 단순히 에러를 던지는 함수
 * @param {string} msg
 */
export const err = msg => {
  throw new Error(msg);
};

export const commaDecimal = num => {
  if (Number.isNaN(num)) return '';
  return num.toLocaleString().replace(/\.0+$/, '');
};

export const isTouch = () => (typeof window !== 'undefined' && 'ontouchstart' in window);

export const fixedRound = (value, places) => {
  const multiplier = 10 ** places;
  return Math.round(value * multiplier) / multiplier;
};

export const setCookie = (name, value) => {
  const expire = new Date();
  expire.setFullYear(expire.getFullYear() + 1);
  document.cookie = `${name}=${encodeURIComponent(value)}; path=/; EXPIRES=${expire.toUTCString()}`;
};

export const getCookie = name => {
  let value = null;
  if (document.cookie) {
    const array = document.cookie.split((`${name}=`));
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      value = decodeURIComponent(arraySub[0]);
    }
  }
  return value;
};

export const toggleClass = (el, c) => {
  if (el.classList.contains(c)) {
    el.classList.remove(c);
  } else {
    el.classList.add(c);
  }
};

export const closest = (el, selector) => {
  if (el.closest) return el.closest(selector);
  let t = el;
  while (t) {
    if (t.matches(selector)) return t;
    if (t === document.body) return null;
    t = t.parentNode;
  }
  return null;
};

export const elementIndex = el => {
  if (!el.parentNode) return -1;
  return Array.prototype.indexOf.call(el.parentNode.childNodes, el);
};

export const routeString = route => (route
  ? route.path + (_.isEmpty(route.query) ? '' : `?${qs.stringify(route.query)}`)
  : window.location.pathname + window.location.search);

export const values = (...args) => _.keyBy(args);

export const sleep = t => new Promise(resolve => setTimeout(resolve, t));

export const flattenGroup = raw => Object.keys(raw)
  .map(groupKey => Object.keys(raw[groupKey])
    .map(key => ({ [(groupKey === 'common' ? '' : `${groupKey}.`) + key]: raw[groupKey][key] })))
  .reduce((groupAcc, group) => ({
    ...groupAcc,
    ...group.reduce((nodeAcc, node) => ({ ...nodeAcc, ...node }), {}),
  }), {});

export const getUseAsyncDataComponents = matchedComponents => {
  const acc = [];
  let prev = null;
  let i = 0;
  let
    list = matchedComponents;
  while (i < list.length) {
    const item = list[i];
    if (item.asyncData) acc.push(item);
    if (item.components) {
      prev = [i + 1, list, prev];
      list = Object.keys(item.components).map(k => item.components[k]);
      i = 0;
    } else {
      i += 1;
    }
    while (i >= list.length && prev) [i, list, prev] = prev;
  }
  return acc;
};
