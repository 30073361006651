<template>
  <div><slot/></div>
</template>

<script>
import { sleep } from '@shared/utils/commonUtils';

export default {
  name: 'PopoverItem',
  props: {
    useClick: { type: Boolean, default: false },
  },
  methods: {
    checkClickOutside(e) {
      if (!this.$el.contains(e.target)) this.$emit('remove');
    },
  },
  async mounted() {
    if (this.useClick) {
      await sleep(100);
      document.addEventListener('mousedown', this.checkClickOutside);
    }
  },
  beforeDestroy() {
    if (this.useClick) document.removeEventListener('mousedown', this.checkClickOutside);
  },
};
</script>
