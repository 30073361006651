<template>
  <ul validation-errors :class="{error}">
    <li v-for="error in errors" v-tv="error" :key="`${error._rule_}${error._field_}`" />
    <li v-if="placeholder && !error">{{ placeholder }}</li>
  </ul>
</template>
<script>
export default {
  name: 'ValidationErrors',
  props: {
    errors: { type: Array },
    placeholder: { type: String },
  },
  computed: {
    error() {
      return this.errors?.length > 0;
    },
  },
};
</script>
