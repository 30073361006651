export default {
  methods: {
    paramFactory(eventCategory, eventLabel, value) {
      return {
        event_category: eventCategory,
        event_label: eventLabel,
        value,
      };
    },
    getTrackEventParamType(action, value, valueType) {
      const data = {
        LOL_DONATION: this.paramFactory('LOL_donation_data', 'LOL_donation', `${value}_${action}`),
        LOL_ACADEMIA: this.paramFactory('LOL_academia_data', 'LOL_academia', `${value}_${action}`),
        PUBG: this.paramFactory('PUBG_PLS2_data', 'PUBG_PLS2', `${value}_${action}`),
        TFT: this.paramFactory('TFT_gadget_data', 'TFT_gadget', `${value}_${action}`),
      };
      return data[valueType] ?? {
        event_category: `${valueType}_data`,
        event_label: valueType,
        value: `${value}_${action}`,
      };
    },
    trackEvent(action, value, valueType) {
      const gtag = this.getTrackEventParamType(action, value, valueType);
      this.$gtag.event(`${gtag.event_label}_${action}`, gtag);
    },
  },
};
