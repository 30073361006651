<template>
  <button badge-button @click="$emit('click')" :class="{ wcg }">
    <slot />
    <i class="badge" v-if="showZero || count > 0">{{ countForShow }}</i>
  </button>
</template>

<script>
export default {
  name: 'BadgeButton',
  props: {
    count: { type: Number, default: 0 },
    max: { type: Number, default: 99 },
    showZero: { type: Boolean, default: false },
    wcg: { type: Boolean, default: false },
  },
  computed: {
    countForShow() {
      return Math.min(this.count, this.max);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[badge-button] { .rel;
  .badge { .ib; .abs; .rt; .t-xy(50%,-10%); .wh(16); .bgc(#ED6767); .br(50%); .c(#fff); .fs(10); .lh(16); .bold; }
  &.wcg {
    .badge { .t-xy(30%,-10%); .bgc(#F3475B); }
  }
}
</style>
