export default {
  log: e => {
    if (typeof e === 'string') {
      console.log(`${new Date().toISOString()} - ${e}`);
      return;
    }
    let result = e;
    if (result.org) result = result.org;
    if (result.request && result.response) {
      result = `${result.request._header.trim()} 
-------------------------
${JSON.stringify(result.response.data?.summary ?? result.response.data)}`;
    }
    console.log(`${new Date().toISOString()}
${result}
=========================
`);
  },
};
