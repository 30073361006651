<template>
  <PSection pubg-go-top>
    <div ref="scrollToTop" v-prx:fixed :class="{active, 'home': isHome}">
      <!--      <button class="btn-arena" @click="goApply"><img src="/img/pages/icon/btn-arena.svg"/></button>-->
      <button class="btn-top" @click="scrollTo()">
        <img src="/img/pages/icon/btn-top.svg" />
      </button>
    </div>
  </PSection>
</template>
<script>
import gtag from '@shared/mixins/gtag';

export default {
  name: 'PubgGoTop',
  mixins: [gtag],
  data() {
    return {
      moving: false,
      active: false,
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    isHome() {
      return this.$route.name.includes('Home') || this.$route.name.includes('LeagueResult');
    },
  },
  methods: {
    scrollTo(scrollOffset = 0) {
      this.$scroll.scrollMove(scrollOffset, 1000);
    },
    scrollSpyHandler() {
      if (this.$refs.scrollToTop) {
        this.moving = true;
        this.active = window.scrollY > 210;

        setTimeout(() => {
          this.moving = false;
        }, 1500);
      }
    },
    goApply() {
      this.trackEvent(`${this.$route.meta.leagueCode}_landing_challenge_Floating`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/pubg?page=arena');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollSpyHandler();
      window.addEventListener('scroll', this.scrollSpyHandler);
      window.addEventListener('resize', this.scrollSpyHandler);
    });
  },
  beforeDestroy() {
    this.$refs.scrollToTop.style.display = 'none';
    window.removeEventListener('scroll', this.scrollSpyHandler);
    window.removeEventListener('resize', this.scrollSpyHandler);
  },
};
</script>
<style lang="less">
@import '~@/less/proj.less';
[pubg-go-top] { .rel; z-index: 100;
  > div { .abs; .pointer; .block; .z(10); transition: opacity 0.3s; .o(0); .rb(19, 30); pointer-events: none;
    &.active {.o(1); pointer-events: auto;}
    &.home { .pt(60);
      .btn-arena { .hide;}
    }
    > button { .block; .wh(48);
      > img { .wh(100%);}
      &.btn-arena { .mb(12);}
    }
    @media (@tl-up) {
      > div { .rt(58, calc(100vh - 200px)); }
    }
    @media (min-width: 1500px) {
      .rt(calc((50% - 30px) - 680px), calc(100vh - 200px));
    }
  }
}
</style>
