export default () => ({
  namespaced: true,
  state: {
    unReadCount: 0,
    recentReceivedType: '',
  },
  mutations: {
    setUnreadCount(state, payload) {
      state.unReadCount = payload;
    },
    setRecentReceivedType(state, type) {
      state.recentReceivedType = type;
    },
  },
});
