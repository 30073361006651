export default class HomeService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }
  async getHeaderBanners(leagueCode) {
    const url = `/leagues/${leagueCode}`;
    return Promise.all([this.#api.get(`${url}/banners/main`), this.#api.get(`${url}/banners/sub`)]);
  }

  getArena(params) {
    return this.#api.get('/arenas', params);
  }

  getMyTeamHistory({ teamId, cursor, size }) {
    return this.#api.get(`/teams/${teamId}/history`, { cursor, size });
  }

  getMyTeams(params) {
    return this.#api.get('/new/teams', params);
  }
}
