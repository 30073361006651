// http://www.cse.yorku.ca/~oz/hash.html
// https://stackoverflow.com/questions/11120840/hash-string-into-rgb-color
/**
 * djb2 hashing 함수
 * @param {string} str
 * @returns {number}
 */
export const djb2 = str => {
  if (!str) throw new Error('str required');
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i);
  }
  return hash;
};

/**
 * @param {string} str
 * @return {string}
 * @example
 * hashToRgb('apple') === #199e37
 */
export const hashRgb = str => {
  if (!str) throw new Error('str required');
  const hash = djb2(str) & 0xFFFFFF + 0xF000000;
  return `#${hash.toString(16).substr(1)}`;
};

/**
 * @param {string} str
 * @param {Number} min
 * @param {Number} max
 * @example
 * hashToNumber('apple', 0, 100) === 55
 */
export const hashNumber = (str, min, max) => {
  if (!str) throw new Error('str required');
  const hash = djb2(str);
  if (!Number.isInteger(min)) throw 'missing minimum';
  if (!Number.isInteger(max)) throw 'missing maximum';
  return (Math.abs(hash) % (max - min + 1)) + min;
};

/**
 * @description
 * 랜덤한 해쉬 생성
 * 원본 소스 - https://github.com/ai/nanoid/blob/e420d8965fdbc456258963ff067bb80026c3edc6/nanoid.js
 * @param t
 * @returns {string}
 */
export const nanoid = (t = 21) => {
  let e = ''; const
r = crypto.getRandomValues(new Uint8Array(t));
  for (; t--;) {
    const n = 63 & r[t];
    e += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? '_' : '-';
  }
  return e;
};

export const sha256 = async message => {
  if (!message) return null;
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
};
