import { values } from '@shared/utils/commonUtils';
import LoginRequiredModal from '@shared/components/account/LoginRequiredModal.vue';
import axios from 'axios';
import { getSessionStorage } from '@shared/modules/ObjectStorage';

export const NoToast = {};

export default (Vue, app) => {
  const errorHandler = error => {
    if (!error || error === NoToast || error.constructor === axios.Cancel || error.name === 'SendBirdException') return;
    if (error instanceof TypeError) {
      console.error(error);
      return;
    }
    if ([error.code, error[1]].some(code => code === 'UNAUTHORIZED')) {
      const theme = document.querySelector('#app > div > .dark') ? 'dark' : 'light';
      return app.$services.modal.modal(LoginRequiredModal, { theme, contents: 'logoutState' }).then(result => {
        if (!result) return window.location.reload();
        getSessionStorage('auth').set('afterLogin', window.location.href);
        window.location.href = process.env.VUE_APP_MEMBERSHIP_LOGIN_URL;
      });
    }

    let message;
    let translate = true;
    if (typeof error === 'string') {
      message = error;
    } else if (Array.isArray(error)) {
      if (!app.$te(error)) error[error.length - 1] = 'DEFAULT';
      message = error;
    } else if (error._rule_) {
      message = app.$tv(error);
      translate = false;
    } else if (error.code in values('301', '302', '401', '403')) { // router exception ignore
      return;
    } else if (error.code === 'DEFAULT') {
      message = 'unhandledApiError';
      console.error(error.org);
    } else {
      message = 'unhandledError';
      console.error(error);
    }
    app.$toast(message, { translate, type: 'fail' });
  };

  Vue.config.errorHandler = errorHandler;
  window.addEventListener('unhandledrejection', event => event.promise.catch(errorHandler));
};
