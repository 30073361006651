import createApp from '@shared/ssr/createApp';
import clickOutside from '@shared/directive/clickOutside';
import ClientOnly from '@shared/components/general/ClientOnly.vue';
import { accessControl } from '@shared/utils/routerUtils';
import scrollAgency from '@shared/plugins/inertiaScrollAgency';
import vueScroll from 'vue-scroll';
import App from '@/App.vue';
import createStore from '@/store';
import createRouter from '@/router';
import createServiceManager, { servicePlugin } from '@/services';
import i18n, { i18nLoad } from '@/plugins/i18n';
import validate from '@/plugins/validate';
import accessContext from '@/router/accessContext';
import parallax from './plugins/parallax';
import prxData from './prx-data';
import '@bigpi-dakgg/style/dist/css/style.css';

export default createApp({
  App,
createStore,
createRouter,
createServiceManager,
  beforeCreate: (Vue, services, router, store) => {
    Vue.use(i18n);
    Vue.use(servicePlugin, { services });
    Vue.use(validate, { services });
    Vue.use(scrollAgency, { store, coefficient: 0.2 });
    Vue.use(parallax, { store, data: prxData, scrollAgency: Vue.prototype.$scroll, offset: { MS: 60, DM: 80 } });
    Vue.use(vueScroll);
    Vue.directive('clickOutside', clickOutside);
    Vue.component('ClientOnly', ClientOnly);
    router.beforeEach(accessControl(accessContext, store));
    router.beforeEach((to, from, next) => i18nLoad(to).then(next));
  },
});
