import moment from 'moment';

export default i18n => {
  const getKey = (context, key) => {
    const k = Array.isArray(key) ? key.filter(v => v).join('.') : key;
    return k[0] === '_' ? context.$options.lexicon + k.substring(1) : k;
  };

  const proto = (context, key, plural, noMark, options) => {
    const k = getKey(context, key);
    return i18n.translate(k, context.$i18n('lang'), plural, noMark, options);
  };

  /**
   * @param {string|string[]} key
   * @param {Object?} options
   * @returns {string}
   */
  const i18nT = function (key, options) {
    return proto(this, key, false, false, options);
  };

  /**
   * @param {string|string[]} key
   * @param {Object?} options
   * @returns {string|null}
   */
  const i18nTe = function (key, options) {
    return proto(this, key, false, true, options);
  };

  const i18nTk = function (key) {
    const k = getKey(this, key);
    return i18n.subKeys(k, this.$i18n('lang'));
  };

  /**
   * @param {string|string[]} key
   * @param {number} options
   * @returns {string}
   */
  const i18nTc = function (key, options) {
    return proto(this, key, true, false, options);
  };

  const tv = (lang, params) => {
    const type = params._rule_;
    const options = { ...params, _name_: i18n.translate(['validation', 'names', params._field_].join('.'), lang, false, false) };
    return i18n.translate(['validation', 'rules', type].join('.'), lang, false, false, options);
  };

  /**
   * @param {{_rule_: string, _field_: string}} params
   * @return {string}
   */
  const i18nTv = function (params) {
    return tv(this.$i18n('lang'), params);
  };

   /**
   * @param {string|number} time
   * @param {string} format
   * @return {string}
   */
  const i18nFromNow = function (time, format) {
    if (!time) return '';
    const dateFormat = format || this.$t('shared.notification.dateFormat');
    const target = +time - this.$store.getters.timeDiff;
    if (Math.abs(target - new Date()) < 1000) return this.$t('shared.notification.justBefore');
    const date = moment(target);
    const timeDifference = Math.abs(moment.duration(date.diff(moment())).asDays());
    return timeDifference < 6 ? date.fromNow() : date.format(dateFormat);
  };

  /**
   * @description
   * 마지막 접속일시 표시 COG-1163 기획 참조
   * @param {string|number} time
   * @return {string}
   */
  const i18nFromNowAlt = function (time) {
    if (!time) return '';
    const target = +time - this.$store.getters.timeDiff;
    const date = moment(target);
    const now = moment();
    const timeDiff = moment.duration(now.diff(date));
    const lang = this.$i18n('lang');
    const t = (key, value) => i18n.translate(key, lang, false, false, [Math.floor(value)]);
    if (timeDiff.asDays() >= 1) return t('coaching.TM_day_ago', timeDiff.asDays());
    if (timeDiff.asHours() >= 1) return t('coaching.TM_hour_ago', timeDiff.asHours());
    if (timeDiff.asMinutes() >= 1) return t('coaching.TM_minute_ago', timeDiff.asMinutes());
    if (timeDiff.asSeconds() >= 1) return t('coaching.TM_second_ago', timeDiff.asSeconds());
    return '';
  };

  /**
   * @param {Duration} duration
   * @return {string}
   */
  function i18nDuration(duration) {
    if (!duration) return '';
    return moment.duration(duration).humanize();
  }

  /**
   * @description
   * 코칭에서 사용하는 시간 단위를 i18n으로 표현해주는 기능
   * @param {LessonDuration} lessonDuration
   * @return {string}
   */
  function i18nLessonTime(lessonDuration) {
    if (!lessonDuration) return '';
    // 현재 2022.4.7 기준으로 수업용 시간단위에 부합하는 i18n 값이 없어 임시로 형태가 일부 겹치는 것 사용.
    // 나중에 변경할 것
    const timeUnit = (lessonDuration?.unit ?? '').toLowerCase();
    // 린팅을 위한 임시 형변환
    const duration = /** @type{Duration} */ { [timeUnit]: lessonDuration?.value };
    return moment.duration(duration).humanize();
  }

  /**
   * @param {string|number} time
   * @param {string} key
   * @return {string}
   */
  const i18nDate = function (time, key = 'ymd') {
    return moment(+time).format(i18n.translate(`date.${key}`, this.$i18n('lang'), false, false));
  };

  /**
   * @param {'path'|'lang'|'country'|'locale'} key
   * @return {string}
   */
  const i18nProps = function (key) {
    const routeLocale = this.$store?.state?.route?.params?.locale;
    if (key === 'path') return i18n.pathByRouteLocale(routeLocale);
    if (key === 'lang') return i18n.languageByRouteLocale(routeLocale);
    if (key === 'locale') return i18n.localeByRouteLocale(routeLocale);
    if (key === 'country') return i18n.countryByRouteLocale(routeLocale);
    throw '[i18n] invalid key';
  };

  const directiveCommon = {
    bind(el, binding, vnode) {
      const key = [binding.arg === '_' ? vnode.context.$options.lexicon : binding.arg, ...Object.keys(binding.modifiers)].join('.');
      const run = locale => el.innerHTML = i18n.translate(key, locale, binding.name === 'tc', binding.name === 'te', binding.value);
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  const directivePlaceholder = {
    bind(el, binding, vnode) {
      const key = [binding.arg === '_' ? vnode.context.$options.lexicon : binding.arg, ...Object.keys(binding.modifiers)].join('.');
      const run = locale => el.setAttribute('placeholder', i18n.translate(key, locale, false, false, binding.value));
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  const directiveTv = {
    bind(el, binding, vnode) {
      const run = locale => el.innerHTML = tv(locale, binding.value);
      vnode.context.$watch('$store.state.route.params.locale', run);
      run(vnode.context.$i18n('lang'));
    },
  };

  return {
    directives: { common: directiveCommon, tv: directiveTv, placeholder: directivePlaceholder },
    prototypes: { i18nT, i18nTe, i18nTc, i18nTv, i18nTk, i18nFromNow, i18nDate, i18nProps, i18nDuration, i18nLessonTime, i18nFromNowAlt },
  };
};
