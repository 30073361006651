export default class ModalProxy {
  #app;
  #modalQue = [];

  /**
   * @param {{name: string, props?: { options: object }}} component
   * @param {any?} options
   * @param {boolean?} clear
   * @returns {Promise<*>}
   */
  modal(component, options = {}, clear = false) {
    return this.#app
      ? this.#app.$modal(component, options, clear)
      : new Promise((resolve, reject) => this.#modalQue.push({ component, options, resolve, reject }));
  }

  checkModal(component) {
    return this.#app.$checkModal(component);
  }

  block() {
    if (this.#app) this.#app.$block();
  }

  unblock() {
    if (this.#app) this.#app.$unblock();
  }

  setApp(app) {
    this.#app = app;
    while (this.#modalQue.length) {
      const arg = this.#modalQue.shift();
      this.#app.$modal(arg.component, arg.options).then(arg.resolve).catch(arg.reject);
    }
  }
}
