export default class VoteService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
  }

  /**
   * @param {number} targetId
   * @param {string} targetType
   * @returns {Promise<VoteInfo>}
   */
  getVoteInfo({ targetId, targetType }) {
    return this.#api.get('/votes/target', { targetId, targetType });
  }

  /**
   * @param {string} specialId
   * @param {VoteRequestBody} body
   * @returns {Promise<VoteInfo>}
   */
  async createArenaVote({ specialId, body }) {
    try {
      return await this.#api.post(`/lvup-specials/channel/${specialId}/vote`, body);
    } catch ({ code }) {
      throw ['shared.vote.error', code];
    }
  }

  /**
   * @param {VoteRequestBody} body
   * @returns {Promise<VoteInfo>}
   */
  async createFeedVote(body) {
    try {
      return await this.#api.post('/vote', body);
    } catch ({ code }) {
      throw ['shared.vote.error', code];
    }
  }

  /**
   * @param {string} voteId
   * @param {VoteRequestBody} body
   * @returns {Promise<VoteInfo>}
   */
  async editVote({ voteId, body }) {
    try {
      return await this.#api.put(`/votes/${voteId}`, body);
    } catch ({ code }) {
      throw ['shared.vote.error', code];
    }
  }

  /**
   * @param {string} voteId
   * @returns {Promise<VoteInfo>}
   */
  async closeVote(voteId) {
    try {
      return await this.#api.put(`/votes/${voteId}/termination`);
    } catch ({ code }) {
      throw ['shared.vote.error', code];
    }
  }

  /**
   * @param {string} voteId
   * @param {string} voteAnswerId
   * @param {string} answerType
   * @param {string} answerName
   * @returns {Promise<VoteInfo>}
   */
  async selectedVote({ voteId, voteAnswerId, answerType = 'CHOICE', answerName }) {
    return this.#api.post(`/votes/${voteId}/toggle`, { voteAnswerId, answerType, answerName });
  }

  /**
   * @param {string} voteId
   * @param {string} voteAnswerId
   * @returns {Promise<VoteInfo>}
   */
  async canceledVote({ voteId, voteAnswerId }) {
    return this.#api.delete(`/votes/${voteId}/toggle/${voteAnswerId}`);
  }

  async deleteVote({ specialId, voteId }) {
    return this.#api.delete(`/lvup-specials/channel/${specialId}/vote/${voteId}`);
  }

  /**
   * @param {string} specialId
   * @param {number} cursor
   * @param {number} size
   * @param {boolean} isEnd
   * @returns {Promise<VoteList>}
   */
  getVoteHistory({ specialId, cursor = 0, size = 10, isEnd = true }) {
    return this.#api.get(`/lvup-specials/channel/${specialId}/vote`, { cursor, size, isEnd });
  }

  /**
   * @param {string} voteId
   * @returns {Promise<?>}
   */
  getDownload(voteId) {
    return this.#api.get(`/votes/${voteId}/download`, {}, { responseType: 'arraybuffer' });
  }

  setVoteOn({ specialId, voteId, arenaId, stageId }) {
    return this.#api.put(`/lvup-specials/channel/${specialId}/vote/${voteId}/open?arenaId=${arenaId}&stageId=${stageId}`);
  }
}
