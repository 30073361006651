/**
 * localStorage 혹은 sessionStorage 에 json을 사용해서 object 입출력 가능하도록 함
 */
class ObjectStorage {
  #id;
  /**
   * @type {Storage} */
  #storage;

  /**
   *
   * @param {string} id
   * @param {boolean} isPersistent
   */
  constructor(id, isPersistent) {
    this.#id = id;
    this.#storage = isPersistent ? localStorage : sessionStorage;
  }

  /**
   * @param {string} key
   * @returns {any|null}
   */
  get(key) {
    const str = this.#storage.getItem(`${this.#id}#${key}`);
    return str ? JSON.parse(str) : null;
  }

  /**
   * @param {string} key
   * @param {any} value
   */
  set(key, value) {
    if (!key || !value) return;
    this.#storage.setItem(`${this.#id}#${key}`, JSON.stringify(value));
  }

  getGroup(group) {
    const keys = this.get(group);
    if (!keys) return null;
    const acc = {};
    keys.forEach(key => acc[key] = this.get(`${group}@${key}`));
    return acc;
  }

  setGroup(group, key, value) {
    const keys = this.get(group) || [];
    keys.push(key);
    this.set(`${group}@${key}`, value);
    this.set(group, keys);
  }

  /**
   * @param {string} key
   * @returns {any|null}
   */
  pick(key) {
    const result = this.get(key);
    this.remove(key);
    return result;
  }

  /**
   * @param {string} key
   */
  remove(key) {
    this.#storage.removeItem(`${this.#id}#${key}`);
  }

  valueOf() {
    const storage = this.#storage.valueOf();
    return Object.entries(storage).reduce((acc, [key, value]) => {
      const regExp = RegExp(`^${this.#id}#`);

      if (!regExp.test(key)) return acc;
      return { ...acc, [key.replace(regExp, '')]: JSON.parse(value) };
    }, {});
  }

  keys() {
    return Object.keys(this.valueOf());
  }
}

/**
 * @type {(id : string) => ObjectStorage}
 */
export const getPersistentStorage = (() => {
  const map = {};
  return id => map[id] || (map[id] = new ObjectStorage(id, true));
})();

/**
 * @type {(id : string) => ObjectStorage}
 */
export const getSessionStorage = (() => {
  const map = {};
  return id => map[id] || (map[id] = new ObjectStorage(id, false));
})();
