import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import { i18n } from '@/plugins/i18n';
import LanguageRoot from '@/views/pages/LanguageRoot.vue';
import plsRoute_2022_3 from '@/router/pls_2022_3';
import plsRoute_2023_1 from '@/router/pls_2023_1';
import plsRoute_2023_2 from '@/router/pls_2023_2';
import plsRoute_2023_3 from '@/router/pls_2023_3';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const page = path => () => import(`../views/pages${path}.vue`);

Vue.use(VueRouter);
Vue.use(Meta);

const notFound = TARGET_NODE
  ? []
  : [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/404',
      name: 'empty',
      component: page('/Error'),
    },
  ];

const getRoutes = async () => [
  {
    path: '/',
    redirect: '/b/pubg',
  },
  {
    path: '/leagueGuide',
    redirect: '/b/pubg/leagueGuide',
  },
  ...plsRoute_2022_3(),
  ...plsRoute_2023_1(),
  ...plsRoute_2023_2(),
  ...plsRoute_2023_3(),
  ...notFound,
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => {
    if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
      console.error(error);
    }
  });
};

const routerReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => {
    if (isNavigationFailure(error, NavigationFailureType.cancelled)) {
      console.error(error);
    }
  });
};

export default async () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => null,
  routes: [
    {
      path: `/:locale(${i18n.routeMatches()})?`,
      component: LanguageRoot,
      children: await getRoutes(),
    },
  ],
});
