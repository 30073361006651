import _keyBy from 'lodash/keyBy';
import { NoToast } from '@shared/modules/ToastErrorHandler';
import qs from 'qs';
import { getHashValueFromRoute, historyMove, makeHashValues } from '@shared/utils/routerUtils';

export default {
  data() {
    return {
      __shieldTime__: null,
    };
  },
  computed: {
    matchedMedia() {
      return this.$store?.state?.browser?.matchedMedia || '';
    },
    matchedMediaDevice() {
      return this.matchedMedia?.[0];
    },
    lang() {
      return this.$i18n('lang') ?? '';
    },
    locale() {
      return this.$i18n('locale') ?? 'ko';
    },
    routeMeta() {
      return this.$route.matched.reduce((acc, route) => ({ ...acc, ...route.meta }), {});
    },
  },
  methods: {
    isEmpty(v) {
      if (!v) return true;
      if (Array.isArray(v) && v.length === 0) return true;
      return typeof v === 'object' && Object.keys(v).length === 0;
    },
    values(...args) {
      return _keyBy(args);
    },
    /**
     * @typedef {{[key: string]: string}} RouteQuery
     */
    /**
     * @param {string} name
     * @param {object?} params
     * @param {object?} query
     * @returns {Promise<Route>}
     */
    routeName(name, params, query = {}) {
      return this.$router.push({ name, params: { locale: this.$route.params.locale, ...params }, query });
    },
    replaceRouteName(name, params, query = {}) {
      return this.$router.replace({ name, params: { locale: this.$route.params.locale, ...params }, query });
    },
    routePath(path) {
      return this.$router.push(path[0] === '/' ? `${this.$i18n('path')}${path}` : path);
    },
    /**
     * @param {string?} name
     * @param {object?} params
     * @param {object?} query
     * @param {string?} path
     */
    routeMainPath({ name, params, query = {}, path }) {
      const mainUrl = process.env.VUE_APP_HOME_URL;
      const { protocol, host } = window.location;

      if (host.includes('8090') || host.includes('gamecoach.pro')) {
        historyMove(process.env.VUE_APP_LVUP_URL.slice(0, -1) + path);
        return;
      }

      if (mainUrl.includes(`${protocol}//${host}`)) {
        if (name) {
          this.routeName(name, params, query).catch();
        } else {
          this.$router.push(path).catch();
        }
      } else if (path) historyMove(mainUrl + path);
    },
    /**
     * @param {string?} path
     */
    routeWcgPath(path) {
      const wcgUrl = process.env.VUE_APP_WCG_URL;
      const { protocol, host } = window.location;

      if (wcgUrl.includes(`${protocol}//${host}`)) {
        this.$router.push(`${this.$i18n('path')}${path}`).catch();
      } else if (path) historyMove(`${wcgUrl}${this.$i18n('path')}${path}`);
    },
    /**
     * @param {{[string]: string}} obj
     */
    pushHasValues(obj) {
      const orgValues = (!this.$route.hash) ? {} : qs.parse(this.$route.hash.substr(1));
      const newValues = { ...orgValues, ...obj };
      this.$router.push({ hash: makeHashValues(newValues) });
    },
    getHashValue(key) {
      return getHashValueFromRoute(this.$route, key);
    },
    replaceRoutePath(path) {
      return this.$router.replace(path[0] === '/' ? `${this.$i18n('path')}${path}` : path);
    },
    /**
     * @param {RouteQuery} query
     * @returns {RouteQuery}
     */
    getMergedQuery(query) {
      return { ...this?.$route?.query || {}, ...query };
    },
    /**
     * @param {string[]} queryKeys
     * @returns {RouteQuery}
     * */
    getRemovedQuery(queryKeys) {
      const query = { ...this?.$route?.query || {} };
      queryKeys.forEach(qk => delete query[qk]);
      return query;
    },
    /**
     * 정해진 시간 간격 안에 여러번 발생하는 액션은 맨 첫 액션만 동작되도록 함. ( debounce 의 경우 마지막 액션을 사용하여 답답한 느낌을 줄 수 있음 )
     * @param {Number} duration 초단위
     * @returns {Promise<*>}
     */
    timeoutShield(duration = 0.5) {
      return new Promise((resolve, reject) => {
        const now = +new Date();
        if (this.isTimeBreak()) {
          reject(NoToast);
        } else {
          resolve();
        }
        this.__shieldTime__ = now + (duration * 1000);
      });
    },
    isTimeBreak() {
      return this.__shieldTime__ && this.__shieldTime__ > +new Date();
    },
    /**
     * @param event_category
     * @param event_label
     * @param action
     */
    gtag(event_category, event_label, action = 'click') {
      if (this.$gtag && process.env.VUE_APP_GA4_KEY) this.$gtag.event(action, { event_category, event_label });
      else console.log('gtag', event_category, event_label, action);
    },
    /**
     * @param event_category
     * @param event_label
     * @param value
     * @param action
     */
    gtagValue(event_category, event_label, value, action = 'click') {
      if (this.$gtag && process.env.VUE_APP_GA4_KEY) this.$gtag.event(action, { event_category, event_label, value });
    },
  },
};
