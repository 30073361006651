import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { values } from '@shared/utils/commonUtils';
import { AWS_API_URL, AWS_BUCKET, AWS_EXPIRE, AWS_PATH } from '@/constants/aws';

export default class AwsService {
  /** @type{import('axios').AxiosInstance} */
  #api;
  /** @type {ServiceManager} */
  #services;

  constructor(services) {
    this.#api = axios.create({ baseURL: AWS_API_URL });
    this.#api.interceptors.request.use(config => {
      const accessToken = this.#services.store.getState('auth', 'accessToken');
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    this.#services = services;
  }

  /**
   * @returns {'dev'|'stage'|'production'}
   */
  get stage() {
    return {
      stage: 'stage',
      production: 'production',
    }[process.env.VUE_APP_ENV] || 'dev';
  }

  /**
   *
   * @param {Blob | File} file
   * @param {Boolean} compress
   * @param {function({total: number, loaded: number}): void} progress
   * @returns {Promise<UploadResult>}
   */
  async upload(file, compress = true, progress = undefined) {
    this.#services.modal.block();
    /** @type {UploadResult} */
    const { data } = await this.#api.post('/dev/getSignedUrl', {
      bucket: AWS_BUCKET,
      expires: AWS_EXPIRE,
      path: AWS_PATH,
      filename: file.name || '',
      filetype: file.type,
      stage: this.stage,
      requestTime: +new Date(),
    });

    let blob;
    if (compress && file.type in values('image/jpeg', 'image/png')) {
      blob = await imageCompression(file, { maxSizeMB: 3, useWebWorker: true });
    } else {
      blob = file;
    }

    await axios.put(data.presignedurl, blob, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: progress,
    });

    this.#services.modal.unblock();
    return data;
  }
}
