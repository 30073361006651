import _get from 'lodash/get';

const defaultLocaleLanguageMap = {
  kr: 'ko',
  us: 'en',
  jp: 'ja',
  cn: 'zh',
  gb: 'en',
  ca: 'en',
  fr: 'fr',
  fr_ca: 'fr',
};

const defaultLocaleCountryMap = {
  kr: 'kr',
  us: 'us',
  jp: 'jp',
  cn: 'cn',
  gb: 'gb',
  ca: 'ca',
  fr: 'fr',
  fr_ca: 'ca',
};

const koreanWordFinalSoundCheck = word => {
  let lastCode = word.charCodeAt(word.length - 1);
  if (lastCode < 58) return [1, 1, 0, 1, 0, 0, 1, 1, 1, 0][lastCode - 48];
  if (lastCode < 91) lastCode += 32;
  if (lastCode < 123) return [0, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 0, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0][lastCode - 97];
  return (lastCode - 0xAC00) % 28 > 0;
};

export default class BI18n {
  #data;
  #defaultLocale;
  #fallbackLang;
  #localeLanguageMap;
  #localeCountryMap;
  #useLocales;

  constructor({ data, localeLanguageMap = defaultLocaleLanguageMap, localeCountryMap = defaultLocaleCountryMap, useLocales = 'us', defaultLocale = 'us' }) {
    this.#data = data;
    this.#localeLanguageMap = localeLanguageMap;
    this.#localeCountryMap = localeCountryMap;
    this.#useLocales = useLocales;
    this.#defaultLocale = defaultLocale;
    this.#fallbackLang = this.languageByRouteLocale(defaultLocale);
  }

  localeByRouteLocale(routeLocale) {
    return routeLocale || this.#defaultLocale;
  }

  pathByRouteLocale(routeLocale) {
    return !routeLocale || routeLocale === this.#defaultLocale ? '' : `/${routeLocale}`;
  }

  languageByRouteLocale(routeLocale) {
    return this.#localeLanguageMap[routeLocale || this.#defaultLocale];
  }

  countryByRouteLocale(routeLocale) {
    return this.#localeCountryMap[routeLocale || this.#defaultLocale];
  }

  matches() {
    return this.#useLocales.split(',').filter(locale => locale !== this.#defaultLocale);
  }

  routeMatches() {
    return this.matches().join('|');
  }

  /**
   * @param {string} key
   * @param {string} lang
   * @param {boolean} plural
   * @param {boolean} noMark
   * @param {*?} options
   * @returns {string}
   */
  translate(key, lang, plural, noMark, options) {
    if (!key) throw new Error('[BI18n] empty key');
    let curr = _get(this.#data[lang], key);
    curr = curr ?? _get(this.#data[this.#fallbackLang], key);
    let t = curr
      ? (typeof curr === 'string')
        ? curr
        : (curr[lang] || curr[this.#fallbackLang])
      : undefined;
    if (t === undefined) return noMark ? null : `@@${key}@@`;
    let params;
    if (plural) {
      const pl = t.split('|').map(s => s.trim());
      if (typeof options !== 'number') throw new Error('[BI18n] only number option is allowed for pluralization');
      if (options < 1) [t] = pl; // count가 0 혹은 음수라면 첫번째
      else if (options > 1) t = pl[pl.length - 1]; // count가 1 보다 크다면 마지막 항목
      else if (pl.length > 2) [, t] = pl; // count가 1 이고 복수항목이 2개 이상이면 2번째
      else [t] = pl; // 아니면 첫번째
      params = { n: options, count: options };
    } else {
      params = options; // || paramInKey;
    }
    if (params) {
      return t.replace(/{([\w.]+)(?:\|([^,}]+),([^,}]+))?}/g, (_, key, v1, v2) => {
        const word = _get(params, key);
        if (word === undefined) return `!!${key}!!`;
        if (v1 && v2) return word + (koreanWordFinalSoundCheck(word) ? v1 : v2);
        return word;
      });
    }
    return t;
  }
}
