import qs from 'qs';
import _pickBy from 'lodash/pickBy';

/**
 * 기존 url 에 query string 을 추가함
 * @param {string} url
 * @param {object} query
 * @param {boolean?} trim
 * @returns {string}
 */
export const appendQuery = (url, query, trim = false) => url + (url.indexOf('?') > 0 ? '&' : '?') + qs.stringify(trim ? _pickBy(query) : query);

/**
 * @param {string} query
 * @returns {ObjectLiteral}
 */
export const parseQuery = query => {
  const result = {};
  if (!query || query.indexOf('?') === -1) return result;
  const q = query.split('?')[1];
  const arr = q.split('&');
  arr.forEach(set => {
    if (set.indexOf('=') > -1) {
      const pair = set.split('=');
      result[pair[0]] = decodeURIComponent(pair[1]);
    }
  });
  return result;
};

export const touchUrl = url => {
  const script = document.createElement('script');
  script.src = url;
  document.body.appendChild(script);
};

/**
 * eq / and로 연결되는 q 를 반환함
 * @param {Object} query
 * @returns
 */
export const eqLikeLink = query => {
  const likeKeys = ['title'];
  return Object.entries(query).reduce((acc, cur) => {
    const [k, v] = cur;
    if (!v || typeof v === 'object') return acc;

    const link = likeKeys.includes(k) ? 'like' : 'eq';
    return [...acc, `${k} ${link} ${v}`];
  }, []).join(' and ');
};

/**
 * like / and로 연결되는 q 를 반환함
 * @param {string} key
 * @param {Array} list
 * @returns
 */
export const likeLink = (key, list = []) => list.map(v => `${key} like ${v}`).join(' and ');

export const httpCheck = str => (/http:\/\/|https:\/\//.test(str) ? str : `https://${str}`);

/**
 * @param {string|undefined} url
 * @returns {{type: 'twitch'|'youtube'|'tiktok'|'none', id: string}}
 */
export const parseVideoUrl = url => {
  if (!url) return { type: 'none', id: '' };
  const type = url.includes('twitch') ? 'twitch'
    : url.includes('tiktok') ? 'tiktok'
    : 'youtube';
  const id = type === 'twitch' ? url.match(/twitch.tv\/([^?]+)/)?.[1] ?? ''
    : type === 'tiktok' ? url.match(/\/(\d{15,})/)?.[1] ?? ''
    : url.match(/\W([\w-]{11,})/i)?.[1] ?? '';
  return { type, id };
};
