<template>
  <div scroll-holder :class="{ inertia, moving, frozen }">
    <div scroll-pseudo-body></div>
    <div scroll-container :class="containerClass">
      <div scroll-body :class="bodyClass">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollHolder',
  props: {
    containerClass: { type: String, default: 'scroll-container' },
    bodyClass: { type: String, default: 'scroll-body' },
    offsetTargetClass: { type: String, default: null },
    headerOffset: { type: Number, default: 0 },
  },
  data() {
    return {};
  },
  watch: {
  },
  computed: {
    inertia() {
      return this.$store.getters.inertia;
    },
    moving() {
      return this.$store.state.scrollAgency.moving;
    },
    frozen() {
      return this.$store.state.scrollAgency.frozen;
    },
  },
  mounted() {
    this.$scroll.setHolder(this, this.offsetTargetClass, this.headerOffset);
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[scroll-holder] { .rel; }
[scroll-pseudo-body] { .hide; }
[scroll-container] { .rel; }
[scroll-body] { .rel; .bgc(white); .max-w(1920); .mh-c; .crop;}

.inertia {
  [scroll-pseudo-body] { .block; }
  [scroll-container] { .fix; .lt; .f; .-box; .crop; }
  [scroll-body] { .abs; .lt(0,0); .r; will-change: transform; }
}
</style>
