export default {
    title: {
        MS: { r: [0, 2], ty: [-150, 0] },
        TV: { r: [0, 2], ty: [-200, 0] },
        DS: { r: [0, 2], ty: [-450, 0] },
    },
    charA: {
        MS: { r: [3, 10], tx: [300, 0] },
        DS: { r: [2, 7], tx: [500, 0] },
    },
    charB: {
        MS: { r: [2, 8], tx: [300, 0] },
        DL: { },
    },
};
