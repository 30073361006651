import moment from 'moment';

moment.defineLocale('ko', {
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '몇 초',
    ss: '%d초',
    m: '1분',
    mm: '%d분',
    h: '한 시간',
    hh: '%d시간',
    d: '하루',
    dd: '%d일',
    M: '1개월',
    MM: '%d개월',
    y: '1년',
    yy: '%d년',
  },
  meridiem: hour => (hour >= 12 ? '오후' : '오전'),
  weekdays: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
  weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
  weekdaysShort: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'],
});
