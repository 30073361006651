import Logger from '@shared/modules/Logger';
import timeConsole from '@shared/ssr/timeConsole';

export default class InfoService {
  /** @type {ApiConnection} */
  #api;
  /** @type {ServiceManager} */
  #services;
  #log;

  /**
   * @param {ServiceManager} services
   */
  constructor(services) {
    this.#api = services.commonApi;
    this.#services = services;
    this.#log = Logger('InfoService');
  }

  /**
   * 서버로부터 기본 정보를 가져옴
   * 국가목록 아직 사용하는 내용이 없어 일단 주석처리
   */
  async init() {
    try {
      const [
        games,
      ] = await Promise.all([
        this.#api.get('/games'),
      ]);
      this.#services.store.commit('info/setGames', games);
    } catch (e) {
      timeConsole.log(e);
    }
  }

  /**
   * @param {{ gameId?: string, serviceType?: string }?} params
   * @returns {Promise<BannerInfo[]>}
   */
  getBanners(params) {
    return this.#api.get('/banners', params);
  }

  /**
   * @param {string} gameId
   * @param {number} cursor
   * @param {number} size
   * @returns {Promise<SpecialInfoList>}
   */
  getSpecials({ gameId, cursor = 0, size = 5 }) {
    return this.#api.get('/lvup-specials', { gameId, cursor, size });
  }

  /**
   * @param {string} gameId
   * @returns {Promise<MainBannerInfo[]>}
   */
  async getMainBanners(gameId) {
    const [banners, specials] = await Promise.all([
      this.getBanners({ gameId }),
      this.getSpecials({ gameId }),
    ]);

    const normalBanners = banners.map(item => /** @type {MainBannerInfo} */({
      ...item,
      bannerType: 'NORMAL',
      customTypeName: item.customTypeName || 'LVUP_EVENT',
    }));

    const specialBanners = specials.items.map(item => /** @type {MainBannerInfo} */({
      id: item.lvupSpecialId,
      title: item.name,
      styles: {
        bannerImage: item.page.mainBannerUrl,
        headCopy: item.page.mainBannerTitle,
        subCopy: item.page.mainBannerIntroduction,
        logoImage: item.page.mainBannerLogoUrl,
        wideBgColor: item.page.wideBgColor,
        contentType: 'IMAGE',
      },
      bannerType: 'SPECIAL',
      customTypeName: 'LVUP_SPECIAL',
    }));

    return [...specialBanners, ...normalBanners];
  }

  async getBaseInfo(gameId) {
    const tags = {
      lol: 'RIOT_LOL_LEAGUE',
      lol_academia: 'RIOT_LOL_LEAGUE',
      wr: 'RIOT_WR_LEAGUE',
      val: 'RIOT_VAL_LEAGUE',
    };
    if (!tags[gameId]) return;
    const leagueLists = await this.#api.get('/leagues', { tag: tags[gameId] });
    return { ...leagueLists[0], leagueLists };
  }
}
