import _get from 'lodash/get';

/**
 * @param {object} state
 * @returns {{function(object, *)}}
 */
export const makeMutations = state => {
  const result = {};
  Object.keys(state).forEach(key => {
    result[key] = (state, value) => state[key] = value;
  });
  return result;
};

/**
 * @param {string} module
 * @param {string} name
 * @returns {{set: function(*): void, get: function(): any}}
 */
export const mutate = (module, name) => ({
  get() {
    return this.$store.state?.[module]?.[name];
  },
  set(v) {
    this.$store.commit(`${module}/${name}`, v);
  },
});

export const getter = (module, name) => function () {
  return this.$store?.getters[`${module}/${name}`];
};

export const state = (module, name) => function () {
  return _get(this.$store?.state, `${module}.${name}`);
};
