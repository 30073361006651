import BI18n from '@shared/modules/BI18nLegacy';
import createPlugin from '@shared/plugins/i18n';
import './moment.ko';
import LocaleLink from '@shared/plugins/i18n/components/LocaleLink.vue';

const useLocales = process.env.VUE_APP_LOCALES;
const defaultLocale = process.env.VUE_APP_DEFAULT_LOCALE;
const data = {};

export const localeLanguageMap = {
  ko: 'ko',
  en: 'en',
};

export const localeCountryMap = {
  ko: 'kr',
  en: 'kr',
};

export const i18n = new BI18n({ data, localeLanguageMap, localeCountryMap, useLocales, defaultLocale });

export const i18nLoad = async route => {
  const lang = i18n.languageByRouteLocale(route.params.locale);
  if (data[lang]) return;
  const module = await import(`./bundle/${lang}.json`);
  data[lang] = module.default;
};

export default {
  install(Vue) {
    const { directives, prototypes } = createPlugin(i18n);
    Vue.directive('t', directives.common);
    Vue.directive('te', directives.common);
    Vue.directive('tc', directives.common);
    Vue.directive('tv', directives.tv);
    Vue.directive('placeholder', directives.placeholder);
    Vue.prototype.$t = prototypes.i18nT;
    Vue.prototype.$te = prototypes.i18nTe;
    Vue.prototype.$tc = prototypes.i18nTc;
    Vue.prototype.$tv = prototypes.i18nTv;
    Vue.prototype.$fromNow = prototypes.i18nFromNow;
    Vue.prototype.$date = prototypes.i18nDate;
    Vue.prototype.$i18n = prototypes.i18nProps;
    Vue.component('LocaleLink', LocaleLink);
  },
};
