<template>
  <CdnImg avatar
    v-if="useImage"
    :src="imgSrc" :dimension="dimension"
    @click="$emit('click')" @fail="fail" @load="$emit('load')"
  >
    <template #skeleton>
      <div class="skeleton"></div>
    </template>
  </CdnImg>
  <div avatar v-else class="fallback" @click="$emit('click')">
    <!-- 1) Fruit Fallback : GCO -->
    <img v-if="coachingAvatar && id" :src="`https://d3hqehqh94ickx.cloudfront.net/gcaon/icon_profile_gcaon_${fruitPattern}.svg`"
      class="fallback-fruit" alt="fallback-fruit" />
    <!-- 2) User-Profile Fallback : 통합멤버십 및 유저 프로필 -->
    <img v-else-if="userProfile" src='https://d3n9cfdp3fwped.cloudfront.net/profile_image.png'
      class="fallback-membership" alt="fallback-membership" />
    <!-- 3) Pattern-Fallback : LVUP 채널, 팀 프로필 -->
    <svg v-else-if="defaultImgType === 'pattern' && id" xmlns="http://www.w3.org/2000/svg" class="fallback-pattern" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
      <defs>
        <pattern :id="uid" patternUnits="userSpaceOnUse" width="100" height="100">
          <image :xlink:href="`/shared/img/patterns/${pattern}.svg`" width="100" height="100" />
        </pattern>
      </defs>
      <rect width="100" height="100" :fill="backgroundColor" />
      <rect width="100" height="100" :fill="`url(#${uid})`" />
      <text transform="translate(50,55)" fill="white" alignment-baseline="middle" text-anchor="middle" font-size="50">{{ title }}</text>
    </svg>
    <!-- 4) Basic(gray) Fallback : 로고 등 -->
    <span v-else class="fallback-basic" />
  </div>
</template>
<script>
import _padStart from 'lodash/padStart';
import { hashNumber } from '@shared/utils/hashUtils';
import { cdnMemberSrc } from '@shared/utils/cdnUtils';
import colors from '@shared/data/constants/colors';
import CdnImg from '@shared/components/common/CdnImg.vue';

export default {
  name: 'Avatar',
  components: { CdnImg },
  props: {
    info: { type: Object },
    dimension: { type: String, default: '40x40' },
    defaultImgType: { type: String, default: 'pattern' },
    userProfile: { type: Boolean, default: false },
  },
  data() {
    return {
      failed: false,
    };
  },
  computed: {
    img() {
      return this.info?.plainProfileUrl || this.info?.information?.image || this.info?.profileImage || this.info?.mainBannerLogoUrl || this.info?.imageUrl || this.info?.image || this.info?.channelThumbnail || this.info?.thumbnailUrl || this.info?.profileImageUrl || '';
    },
    isUserFallback() {
      return this.img === 'profile_image.png'; // 통합멤버십 AWS에서 프로필 이미지url 이 없는 경우 기본적으로 보내주는 String 값
    },
    imgSrc() {
      return this.userProfile ? cdnMemberSrc(this.img) : this.img;
    },
    coachingAvatar() {
      return this.defaultImgType === 'fruit';
    },
    useImage() {
      return this.img && !this.failed && (this.coachingAvatar ? !this.isUserFallback : true);
    },
    id() {
      return this.info?.metaData?.lvupUserId || this.info?.id || this.info?.userId || this.info?.lvupSpecialId || this.info?.teamId || '';
    },
    fruitPattern() {
      return _padStart(hashNumber(this.id, 1, 20), 2, '0');
    },
    name() {
      return this.info?.nickname || this.info?.name;
    },
    title() {
      return this.name?.substr(0, 2).toUpperCase();
    },
    pattern() {
      return _padStart(hashNumber(this.id, 1, 17), 2, '0');
    },
    uid() {
      return `${this.id}-${this.pattern}`;
    },
    backgroundColor() {
      const index = hashNumber(this.id, 0, colors.length - 1);
      return colors[index];
    },
  },
  methods: {
    fail() {
      this.failed = true;
      this.$emit('fail');
    },
    load() {
      this.$emit('load');
    },
  },
  mounted() {
    if (!this.useImage) this.$emit('load');
  },
};
</script>
<style lang="less">
@import '~@shared/less/proj.less';
[avatar] { .ib; .br(50%); user-select: none; box-sizing: content-box; .crop; object-fit: cover;
  .skeleton { .bgc(#404248); .rel; .crop;
    &:after { .shine-anim; }
  }

  &.fallback {
    img.fallback-fruit, img.fallback-membership, .fallback-pattern { .wh(100%); }
    .fallback-basic { .ib; .wh(100%); .bgc(#333); }
  }
}
</style>
