<template>
  <RetryImg cdn-img v-if="active" :src="cdnUrl" :retry-count="retryCount" :retry-interval="retryInterval" @click="$emit('click')" @fail="fail">
    <template #skeleton>
      <slot name="skeleton" />
    </template>
  </RetryImg>
  <img cdn-img v-else-if="fallback" :src="fallback" :alt="alt" @click="$emit('click')" />
</template>

<script>
import RetryImg from '@shared/components/general/RetryImg.vue';
import { appendQuery } from '@shared/utils/urlUtils';
import { cdnSrc } from '@shared/utils/cdnUtils';

export default {
  name: 'CdnImg',
  components: { RetryImg },
  props: {
    src: { type: String, default: '' },
    retryCount: { type: Number, default: 3 },
    retryInterval: { type: Number, default: 700 },
    dimension: { type: String },
    fallback: { type: String },
    alt: { type: String, default: '' },
  },
  data() {
    return {
      failed: false,
    };
  },
  computed: {
    active() {
      return this.src && this.src !== 'null' && !this.failed;
    },
    cdnUrl() {
      return appendQuery(cdnSrc(this.src), { d: this.dimension?.toLowerCase() }, true);
    },
  },
  methods: {
    fail() {
      this.$emit('fail');
      this.failed = true;
    },
  },
};
</script>
