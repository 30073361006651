<template>
  <div toast-container>
    <div v-for="toast in toastStack" :key="toast.id" :style="{transform: `translateY(${toast.top})`}" :class="[toast.type, toast.out ? 'out' : null]" @click="remove(toast.id)">
      <svg-success v-if="toast.type === 'success'" />
      <svg-fail v-else />
      <span v-html="toast.message" />
    </div>
  </div>
</template>

<script>
import SvgFail from '@shared/graphics/svg-fail.vue';
import SvgSuccess from '@shared/graphics/svg-success.vue';

export default {
  name: 'ToastContainer',
  components: { SvgSuccess, SvgFail },
  data() {
    return {
      seq: 0,
      toastStack: [],
      intervalId: -1,
      fullscreen: false,
      fullscreenStyle: {},
      toastHolder: null,
    };
  },
  methods: {
    toast(message, { type = 'success', dura = process.env.VUE_APP_TOAST_DURATION } = {}) {
      this.toastStack.unshift({ message, type, life: dura * 10 + 5, id: this.seq++, out: false });
      if (this.intervalId === -1) this.intervalId = setInterval(this.tick, 100);
    },
    remove(id) {
      this.toastStack = this.toastStack.filter(t => t.id !== id);
      this.emptyCheck();
    },
    tick() {
      this.toastStack.forEach((t, i) => {
        t.life -= 1;
        t.top = `${i * 98}%`;
        if (t.life < 5) t.out = true;
      });
      this.toastStack = this.toastStack.filter(t => t.life > 0);
      this.emptyCheck();
    },
    emptyCheck() {
      if (this.toastStack.length === 0) {
        clearInterval(this.intervalId);
        this.intervalId = -1;
      }
    },
    fullscreenChanged() {
      const fullscreen = !!document.fullscreenElement;
      if (this.fullscreen === fullscreen) return;
      /** @type {HTMLElement} */
      const el = this.$el;
      if (fullscreen) {
        document.fullscreenElement.appendChild(el);
      } else {
        this.toastHolder.$el.appendChild(el);
      }
      this.fullscreen = fullscreen;
    },
    init(holder) {
      this.toastHolder = holder;
      this.fullscreenChanged();
      document.addEventListener('fullscreenchange', this.fullscreenChanged);
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/asset.less';

[toast-container] { .fix; .wf; .lt; .z(6000); .fs(14,20); .c(#000);
  div { .abs; .lt; .wh(100%, auto); .flex; .justify-center; .items-center; .tr-to(0.5s); .t-y(-100%); .p(15, 10); .gap(10);
    &.success { .bgc(#12d5a3); }
    &.fail { .bgc(#e9b718); }
    &.out { .o(0); }
    svg { .wh(20); }
    span { .ib; }
  }
}
</style>
