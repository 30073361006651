const view = path => () => import(`../views/pages/pls_2023_2/${path}.vue`);
const page = path => () => import(`../views/pages${path}.vue`);
const meta = { leagueCode: '2023_PLS_SEASON_2', metaKey: 'pls_2023_2' };

export default () => [
  {
    path: '/b/:id(pubg)/:league(pls_2023_2)',
    name: 'pubgHome_2023_02',
    component: view('PubgHome'),
    meta: { ...meta, page: 'home' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2023_2)/leagueGuide',
    name: 'pubgLeagueGuide_2023_02',
    component: view('PubgIntro'),
    meta: { ...meta, page: 'guide' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2023_2)/leagueResult',
    name: 'pubgLeagueResult_2023_02',
    component: view('PubgResult'),
    meta: { ...meta, page: 'result' },
  },
  {
    path: '/b/:id(pubg)/:league(pls_2023_2)/newsFeed',
    name: 'newsFeed_2023_02',
    component: page('/NewsFeed'),
    meta: { ...meta, page: 'feed' },
  },
];
