// eslint-disable-next-line vue/no-duplicate-attributes
import Vue from 'vue';
import { routeString } from '@shared/utils/routerUtils';
import { getSessionStorage } from '@shared/modules/ObjectStorage';
import _find from 'lodash/find';
import { isEmojiSupported } from '@shared/modules/emoji';

const screens = ['DL', 'DM', 'DS', 'TL', 'TP', 'ML', 'MM'];

const polyfill = () => {
  if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  if (typeof Object.values !== 'function') Object.values = o => Object.keys(o).map(k => o[k]);
  // eslint-disable-next-line no-extend-native
  if (!Array.prototype.find) Array.prototype.find = fn => _find(this, fn);
};

/**
 * 브라우저 상태를 store에 싱크
 * @param {Store} store
 */
export const sync = store => {
  if (typeof window === 'undefined') return;
  let mobile;
  let touch;
  let shrink;
  let screenStatus = null;
  let lastHeight = Number.MAX_VALUE;

  let lastScroll = 0;
  let scrollDirection = 'up';
  let scrollByWheel = false;
  let byWheel = -1;

  const getMatchedMedia = () => {
    for (const screen of screens) {
      if (window.matchMedia(`(min-width: ${process.env[`VUE_APP_SCREEN_${screen}`]}px)`).matches) return screen;
    }
    return 'MS';
  };

  const getScreenStatus = () => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    if (mobile) {
      const dh = vh - lastHeight;
      lastHeight = vh;
      shrink = dh < 0;
    } else {
      shrink = false;
    }
    const matchedMedia = getMatchedMedia();
    const orientation = matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
    return { matchedMedia, vw, vh, orientation, shrink };
  };

  const onWheel = () => {
    clearTimeout(byWheel);
    byWheel = setTimeout(() => {
      byWheel = -1;
    }, 3000);
  };

  const onScroll = () => {
    if (store.state.browser.scrollFrozen) return;
    const top = window.pageYOffset;
    scrollDirection = lastScroll <= top && top > 0 ? 'down' : 'up';
    lastScroll = top;
    scrollByWheel = byWheel > -1;
    store.commit('browser/scrollDirection', scrollDirection);
    store.commit('browser/scrollByWheel', scrollByWheel);
  };

  const onResize = () => {
    screenStatus = getScreenStatus();

    /** @description css 에서 innerHeight 의 변수 값을 지정해준다! */
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--innerHeight', `${screenStatus.vh}px`);

    store.commit('browser/matchedMedia', screenStatus.matchedMedia);
    store.commit('browser/orientation', screenStatus.orientation);
    store.commit('browser/shrink', screenStatus.shrink);
  };

  const detect = () => {
    touch = 'ontouchstart' in window;
    mobile = !!navigator.userAgent.match(/(phone|pad|android)/i) || (!!navigator.userAgent.match(/mac/i) && navigator.maxTouchPoints >= 5);
    shrink = mobile;
    const safariVersion = navigator.userAgent.match(/version\/([\d.]+) safari/i)?.[1];
    const oldSafari = safariVersion && safariVersion < 16;
    const emojiSupported = isEmojiSupported('🇨🇭');
    store.commit('browser/touch', touch);
    store.commit('browser/mobile', mobile);
    store.commit('browser/shrink', shrink);
    store.commit('browser/emojiSupported', emojiSupported);
    store.commit('browser/oldSafari', oldSafari);
  };

  polyfill();
  window.addEventListener('resize', onResize);
  window.addEventListener('scroll', onScroll);
  window.addEventListener('wheel', onWheel);
  window.addEventListener('mousedown', () => byWheel = -1);
  Vue.nextTick(() => {
    detect();
    onResize();
  });
};

/**
 * @extends {ScrollHandler}
 */
class DefaultScrollHandler {
  scrollTo(v) {
    window.scroll(0, v);
  }

  getScrollTop() {
    return window.pageYOffset;
  }

  getScrollHeight() {
    return document.documentElement.scrollHeight;
  }
}

/**
 * Browser Scroll 히스토리 복구
 * @param {VueRouter} router
 * @param {Store} store
 * @param {RegExp[]?} ignoreInnerRoutes
 * @param {ScrollHandler} scrollHandler
 */
const useScrollHistory = ({ router, store, ignoreInnerRoutes, scrollHandler = new DefaultScrollHandler() } = {}) => {
  if (typeof window === 'undefined') return;
  let popStateDetected = false;
  let firstRoute = true;
  const storage = getSessionStorage('browser');

  /**
   * @param {Route} to
   * @param {Route} from
   */
  const stayRoute = (to, from) => (ignoreInnerRoutes && ignoreInnerRoutes.some(exp => exp.test(to.path) && exp.test(from.path)))
    || to?.matched[to.matched.length - 1]?.instances === from?.matched[from.matched.length - 1]?.instances;

  const heightReady = h => new Promise(resolve => {
    let timeout = 30;
    const handler = () => {
      const scrollHeight = scrollHandler.getScrollHeight();
      if (scrollHeight > 0 ? scrollHeight >= h : --timeout <= 0) {
        resolve();
      } else {
        setTimeout(handler, 40);
      }
    };
    handler();
  });

  const restoreScroll = route => {
    const v = storage.pick(route);
    return v || [0, 0];
  };

  const saveScroll = route => {
    storage.set(route, [scrollHandler.getScrollTop(), scrollHandler.getScrollHeight()]);
  };

  const saveState = () => saveScroll(routeString());

  const checkScroll = (type, to) => {
    Vue.nextTick(async () => {
      let v;
      let h;

      if (type === 'popState') {
        [v, h] = restoreScroll(routeString(to));
      } else if (type === 'pageShow') {
        const r = routeString();
        const t = storage.get(r);
        if (!t) return;
        storage.remove(r);
        if (!store.state.browser.popState) return;
        [v, h] = t;
      } else {
        v = 0;
        h = 0;
      }

      await heightReady(h);
      scrollHandler.scrollTo(v);
    });
  };

  window.addEventListener('pagehide', saveState);
  window.addEventListener('pageshow', () => checkScroll('pageShow'));

  window.addEventListener('beforeunload', saveState);
  window.addEventListener('popstate', () => {
    popStateDetected = true;
  });

  router.beforeEach((to, from, next) => {
    store.commit('browser/popState', popStateDetected);
    popStateDetected = false;
    saveScroll(routeString(from));
    next();
  });

  router.afterEach((to, from) => {
    if (stayRoute(to, from)) return;
    checkScroll(store.state.browser.popState ? 'popState' : 'normal', to);
    if (firstRoute) firstRoute = false;
  });
};

export default { sync, useScrollHistory };

/**
 * BrowserSync Store
 */
export const store = () => ({
  namespaced: true,
  state: {
    touch: true,
    mobile: false,
    matchedMedia: null,
    orientation: null,
    shrink: null,
    scrollDirection: null,
    scrollByWheel: false,
    popState: false,
    scrollFrozen: false,
    emojiSupported: false,
    oldSafari: false,
  },
  mutations: {
    touch(state, val) {
      state.touch = val;
    },
    mobile(state, val) {
      state.mobile = val;
    },
    matchedMedia(state, val) {
      state.matchedMedia = val;
    },
    orientation(state, val) {
      state.orientation = val;
    },
    shrink(state, val) {
      state.shrink = val;
    },
    scrollDirection(state, val) {
      state.scrollDirection = val;
    },
    scrollByWheel(state, val) {
      state.scrollByWheel = val;
    },
    popState(state, val) {
      state.popState = val;
    },
    freezeScroll(state) {
      state.scrollFrozen = true;
    },
    releaseScroll(state) {
      state.scrollFrozen = false;
    },
    emojiSupported(state, val) {
      state.emojiSupported = val;
    },
    oldSafari(state, val) {
      state.oldSafari = val;
    },
  },
});
