import PopoverContainer from './components/PopoverContainer.vue';

export default (Vue, closeComponent) => {
  const getContainer = c => {
    const root = c.$root;
    if (!root._popoverContainer) {
      root._popoverContainer = new (Vue.extend(PopoverContainer))({ parent: root, propsData: { closeComponent } });
      root._popoverContainer.$mount();
      root.$el.appendChild(root._popoverContainer.$el);
    }
    return root._popoverContainer;
  };
  return {
    bind(el, binding, vnode) {
      if(binding.value.disabled) return;
      el.binding = binding;

      const add = () => {
        getContainer(vnode.context).add({ el, context: vnode.context });
      };

      const remove = () => {
        getContainer(vnode.context).remove(el);
      };

      if (binding.value.useClick) {
        el.addEventListener('mousedown', add);
        vnode.context.$once('hook:beforeDestroy', () => {
          el.removeEventListener('mousedown', add);
        });
      } else {
        el.addEventListener('mouseenter', add);
        el.addEventListener('mouseleave', remove);
        vnode.context.$once('hook:beforeDestroy', () => {
          el.removeEventListener('mouseenter', add);
          el.removeEventListener('mouseleave', remove);
        });
      }
    },
    update(el, binding, vnode) {
      if(binding.value.disabled) return;
      el.binding = binding;
      getContainer(vnode.context).update({ el, context: vnode.context });
    },
  };
};
